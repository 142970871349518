var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        width: "60%",
        top: "7vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.taskTitle) } }),
        ]
      ),
      _c(
        "el-steps",
        {
          attrs: {
            active: _vm.active,
            "align-center": "",
            "finish-status": "success",
            simple: "",
          },
        },
        [
          _c("el-step", { attrs: { title: "选择任务类型" } }),
          _c("el-step", { attrs: { title: "设置任务内容" } }),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.taskType,
              expression: "taskType",
            },
          ],
          staticClass: "task-list",
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            {
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(1)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/tuwen.png") },
                }),
                _c("p", [_vm._v("图文")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(2)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/wailian.png") },
                }),
                _c("p", [_vm._v("外链")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(5)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/wailian.png") },
                }),
                _c("p", [_vm._v("python")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(3)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/kaoshi.png") },
                }),
                _c("p", [_vm._v("测试")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(4)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/bianchen.png"),
                  },
                }),
                _c("p", [_vm._v("编程")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(6)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/kaoshi.png") },
                }),
                _c("p", [_vm._v("PDF")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(7)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/wailian.png") },
                }),
                _c("p", [_vm._v("视频")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(8)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/tuwen.png") },
                }),
                _c("p", [_vm._v("文件")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(9)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/tuwen.png") },
                }),
                _c("p", [_vm._v("goc")]),
              ]),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { span: 6 },
              nativeOn: {
                click: function ($event) {
                  return _vm.seleTask(10)
                },
              },
            },
            [
              _c("div", { staticClass: "task-item" }, [
                _c("img", {
                  attrs: { src: require("../../../assets/images/kaoshi.png") },
                }),
                _c("p", [_vm._v("scratch")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.taskTypeNum === 1
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("image-text", {
                ref: "imageText",
                attrs: {
                  lessonId: _vm.lessonId,
                  type: "2",
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 8
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("file-text", {
                ref: "fileText",
                attrs: {
                  lessonId: _vm.lessonId,
                  type: "8",
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 10
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("scratch-text", {
                ref: "scratchText",
                attrs: {
                  lessonId: _vm.lessonId,
                  type: "10",
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 2
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("outside-link", {
                ref: "outsideLink",
                attrs: {
                  lessonId: _vm.lessonId,
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 6
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("pdf-task", {
                ref: "pdftask",
                attrs: {
                  lessonId: _vm.lessonId,
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 7
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("video-task", {
                ref: "videotask",
                attrs: {
                  headers: _vm.headers,
                  lessonId: _vm.lessonId,
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 9
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("goc-task", {
                ref: "goctask",
                attrs: {
                  lessonId: _vm.lessonId,
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 5
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("image-text", {
                ref: "imageText",
                attrs: {
                  lessonId: _vm.lessonId,
                  type: "5",
                  editTaskData: _vm.editTaskData,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 3
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("exam-test", {
                ref: "examTest",
                attrs: {
                  lessonId: _vm.lessonId,
                  editTaskData: _vm.editTaskData,
                  CourseInfo: _vm.CourseInfo,
                },
                on: {
                  updateTask: _vm.updateTask,
                  getExamInfo: _vm.getExamInfo,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.taskTypeNum === 4
        ? _c(
            "el-row",
            { staticStyle: { "margin-top": "55px" } },
            [
              _c("edit-code", {
                ref: "editCode",
                attrs: {
                  lessonId: _vm.lessonId,
                  editTaskData: _vm.editTaskData,
                  CourseInfo: _vm.CourseInfo,
                  taskTitle: _vm.taskTitle,
                },
                on: { updateTask: _vm.updateTask },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.seleTaskBtns
        ? _c(
            "el-row",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              !_vm.editTaskData
                ? [
                    _vm.active > 1
                      ? _c(
                          "el-button",
                          { attrs: { size: "small" }, on: { click: _vm.prev } },
                          [_vm._v("上一步")]
                        )
                      : _c(
                          "el-button",
                          { attrs: { size: "small" }, on: { click: _vm.next } },
                          [_vm._v("下一步")]
                        ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.saveTask },
                      },
                      [_vm._v("保存")]
                    ),
                  ]
                : [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.saveTask },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }