<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课件视频管理</span>
    </div>
    <!-- isSort：{{isSort}} -->
    <!-- coursewareList: {{coursewareList}}
    <hr>
    oldList：{{oldList}}
    <hr>
    newList：{{newList}} -->
    <el-row class="mb-20">
      <el-select v-model="type" placeholder="请选择附件类型" class="mr-10" @change="search">
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <!-- <el-button type="info" size="medium" @click="search">查询</el-button> -->
      <el-button type="primary" size="medium" @click="mySort" v-text="SortText"></el-button>
    </el-row>
    <el-table :data="coursewareList" border fit highlight-current-row ref="dragTable" row-key="id">
      <el-table-column label="排序" align="center" width="60" v-if="isSort">
      <!-- <el-table-column label="排序" align="center" width="60"> -->
        <span class="el-icon-rank"></span>
      </el-table-column>
      <el-table-column label="名称" align="center" prop="title"></el-table-column>

      <el-table-column label="类型" align="center" prop="type"></el-table-column>

      <el-table-column label="创建日期" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime.substring(0,scope.row.createTime.length-5) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="viewCourseWare(scope.row)">查看</el-button>
          <el-button size="small" type="primary" plain @click="deleteCourseWare(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <span slot="footer" class="dialog-footer" v-if="isSort">
      <el-button type="primary" @click="confirmSort">确定排序</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getListAttachment, delAttachment, reSortAttachment } from '@/api/research/curriculum'
import { getVideoUrl } from '@/api/video/videomanage'
import Sortable from 'sortablejs'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    applyCourseData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      courseUnitId: '',
      resetUse: {},
      coursewareList: [],
      oldList: [],
      newList: [],
      isSort: false,
      SortText: '',
      type: '',
      typeOptions: [{
        value: '课件',
        label: '课件'
      }, {
        value: '补课视频',
        label: '补课视频'
      }]
    }
  },
  methods: {
    mySort () {
      this.isSort = !this.isSort
      // console.log('isSort', this.isSort)
      if (!this.isSort) {
        this.SortText = '课件视频排序'
      } else {
        this.SortText = '退出排序'
      }
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getCourseWareList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getCourseWareList()
    },

    openDialog () {
      this.type = '课件'
      this.isSort = false
      if (!this.isSort) {
        this.SortText = '课件视频排序'
      } else {
        this.SortText = '退出排序'
      }
      this.courseUnitId = this.applyCourseData.id
      this.getCourseWareList()
      this.$nextTick(() => {
        this.setSort()
      })
    },

    search () {
      this.pagination.currentPage = 1
      this.getCourseWareList()
    },

    // dateKey 时间处理
    datefmt (date) {
      let res = ''
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      res = `${year}-${month}-${day}`
      return res
    },

    async getCourseWareList () {
      const res = await getListAttachment({
        courseUnitId: this.courseUnitId,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        type: this.type
      })
      this.pagination.total = res.body.total
      this.coursewareList = res.body.list
      this.oldList = this.coursewareList.map(v => v.id)
      this.newList = this.oldList.slice()
    },

    setSort () {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: (dataTransfer) => {
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.coursewareList.splice(evt.oldIndex, 1)[0]
          this.coursewareList.splice(evt.newIndex, 0, targetRow)
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
          this.newList.splice(evt.newIndex, 0, tempIndex)
        }
      })
    },

    async confirmSort () {
      await reSortAttachment({
        cuId: this.courseUnitId,
        cuaIds: this.newList
      })
      window.$msg('排序成功')
      this.getCourseWareList()
    },

    close () {
      this.$emit('close')
    },

    viewCourseWare (row) {
      if (row.type === '课件') {
        let routeData = this.$router.resolve({
          path: '/research/preview-accessory',
          query: {
            title: row.title,
            sourceContent: row.content,
            id: row.id
          }
        })
        window.open(routeData.href, '_blank')
      } else if (row.type === '补课视频') {
        if (row.content.indexOf('http') === -1) {
          this.getVideoUrl(row.content)
        } else {
          let routeData = this.$router.resolve({
            path: '/research/preview-video',
            query: {
              pathUrl: row.content
            }
          })
          window.open(routeData.href, '_blank')
        }
      }
    },

    async getVideoUrl (fileId) {
      const res = await getVideoUrl({
        fileId
      })
      if (res.code === 1) {
        window.$msg(res.msg, 2)
      } else if (res.code === 0) {
        let routeData = this.$router.resolve({
          path: '/research/preview-video',
          query: {
            pathUrl: res.data
          }
        })
        window.open(routeData.href, '_blank')
      }
    },

    // 删除课件
    deleteCourseWare (row) {
      this.$confirm('是否删除当前文件', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delAttachment({ id: row.id })
          window.$msg('删除成功')
          this.getCourseWareList()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>>
