var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticStyle: { "padding-bottom": "25px" } },
    [
      _c("h3", { staticClass: "tab-title" }, [_vm._v("课程计划")]),
      _c(
        "el-row",
        { staticClass: "task-list" },
        [
          _c(
            "span",
            [
              _vm._v("总课次："),
              _c("font", {
                staticClass: "taskNum",
                domProps: { textContent: _vm._s(_vm.LessonNum) },
              }),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "task-span" },
            [
              _vm._v("总任务数："),
              _c(
                "font",
                {
                  staticClass: "taskNum",
                  domProps: { textContent: _vm._s(_vm.TaskNum) },
                },
                [_vm._v("5")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "curriculum:LessonAdd",
                  expression: "'curriculum:LessonAdd'",
                },
              ],
              staticClass: "task-right task-cursor",
              attrs: { type: "text", icon: "el-icon-plus" },
              on: { click: _vm.addLesson },
            },
            [_vm._v("添加课")]
          ),
          _c("div", { staticClass: "clearfix" }),
          _c(
            "draggable",
            _vm._b(
              {
                staticStyle: { "padding-left": "20px" },
                attrs: {
                  reverse: _vm.reverse,
                  tag: "el-collapse",
                  move: _vm.getdata,
                },
                on: { end: _vm.datadragEnd },
                model: {
                  value: _vm.curriculumArr,
                  callback: function ($$v) {
                    _vm.curriculumArr = $$v
                  },
                  expression: "curriculumArr",
                },
              },
              "draggable",
              _vm.dragOptionsMneu,
              false
            ),
            _vm._l(_vm.curriculumArr, function (item, index) {
              return _c(
                "el-collapse",
                {
                  key: index,
                  attrs: { id: item.id },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    {
                      attrs: { name: index },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showTaskList(item, index)
                        },
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("span", [
                            _vm._v(
                              "第" +
                                _vm._s(Number(Number(index) + 1)) +
                                "课：" +
                                _vm._s(item.title)
                            ),
                          ]),
                          item.status === "已启用"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-left": "15px",
                                    "margin-top": "5px",
                                  },
                                  attrs: { type: "success", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.gotoMakeStatus(
                                        item.id,
                                        1,
                                        item.title
                                      )
                                    },
                                  },
                                },
                                [_vm._v("已发布")]
                              )
                            : _vm._e(),
                          item.status === "已禁用"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-left": "15px",
                                    "margin-top": "5px",
                                  },
                                  attrs: { type: "warning", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.publishCourseUnit(item.id)
                                    },
                                  },
                                },
                                [_vm._v("未发布")]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "15px" } },
                            [
                              item.group !== ""
                                ? _c(
                                    "el-button",
                                    { attrs: { type: "text" } },
                                    [
                                      _vm._v("所属分组:"),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { type: "text" },
                                        },
                                        [_vm._v(_vm._s(item.group))]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", { staticStyle: { flex: "1" } }),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value: "curriculum:TaskAdd",
                                  expression: "'curriculum:TaskAdd'",
                                },
                              ],
                              staticClass: "task-right task-cursor",
                              attrs: { type: "text", icon: "el-icon-plus" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addTask(item.id)
                                },
                              },
                            },
                            [_vm._v("添加任务")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value: "curriculum:LessonEdit",
                                  expression: "'curriculum:LessonEdit'",
                                },
                              ],
                              staticClass: "task-right task-cursor",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.editLesson(item)
                                },
                              },
                            },
                            [_vm._v("编 辑")]
                          ),
                          _c("div", { staticClass: "clearfix" }),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value: "curriculum:LessonDel",
                                  expression: "'curriculum:LessonDel'",
                                },
                              ],
                              staticClass: "task-right task-cursor",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delLesson(item.id)
                                },
                              },
                            },
                            [_vm._v("删 除")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "task-right task-cursor",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addMissedLesson(
                                    _vm.curriculumArr[index]
                                  )
                                },
                              },
                            },
                            [_vm._v("新增课件视频")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "task-right task-cursor",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.missedLessonManage(
                                    _vm.curriculumArr[index]
                                  )
                                },
                              },
                            },
                            [_vm._v("课件视频管理")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticStyle: { "padding-left": "20px" },
                            attrs: {
                              reverse: _vm.reverse,
                              tag: "el-timeline",
                              move: _vm.getdata,
                            },
                            on: { add: _vm.addClass, end: _vm.datadragTaskEnd },
                            model: {
                              value: item.children,
                              callback: function ($$v) {
                                _vm.$set(item, "children", $$v)
                              },
                              expression: "item.children",
                            },
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(item.children, function (activity, index) {
                          return _c(
                            "el-timeline-item",
                            {
                              key: index,
                              staticClass: "list-group-item",
                              attrs: {
                                parentid: activity.courseUnitId,
                                id: activity.id,
                                "hide-timestamp": true,
                                timestamp: activity.createTime,
                              },
                            },
                            [
                              _vm._v(
                                " 任务" +
                                  _vm._s(index + 1) +
                                  "：" +
                                  _vm._s(activity.title) +
                                  " "
                              ),
                              activity.status === "已启用"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.gotoMakeTaskStatus(
                                            activity.id,
                                            1,
                                            activity.title
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("已发布")]
                                  )
                                : _vm._e(),
                              activity.status === "已禁用"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { type: "warning", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.publishTask(activity.id)
                                        },
                                      },
                                    },
                                    [_vm._v("未发布")]
                                  )
                                : _vm._e(),
                              activity.defOpen
                                ? _c(
                                    "font",
                                    { staticClass: "inline-block ml-15" },
                                    [_vm._v("默认开放")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value: "curriculum:TaskDel",
                                      expression: "'curriculum:TaskDel'",
                                    },
                                  ],
                                  staticClass: "task-right task-cursor",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delTask(activity.id)
                                    },
                                  },
                                },
                                [_vm._v("删 除")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "task-right task-cursor",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.LookTask(
                                        activity,
                                        activity.id,
                                        activity.type
                                      )
                                    },
                                  },
                                },
                                [_vm._v("预 览")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "has",
                                      rawName: "v-has",
                                      value: "curriculum:TaskEdit",
                                      expression: "'curriculum:TaskEdit'",
                                    },
                                  ],
                                  staticClass: "task-right task-cursor",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editTask(activity)
                                    },
                                  },
                                },
                                [_vm._v("编 辑")]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c("el-backtop", {
            attrs: {
              bottom: 40,
              "visibility-height": 50,
              target: ".task-list",
            },
          }),
        ],
        1
      ),
      _c("add-lesson", {
        attrs: { show: _vm.lessonDialog, editLessonData: _vm.editLessonData },
        on: {
          close: function ($event) {
            _vm.lessonDialog = false
            _vm.editLessonData = null
          },
          updateView: _vm.updateView,
        },
      }),
      _c("add-task", {
        attrs: {
          show: _vm.taskDialog,
          lessonId: _vm.lessonId,
          editTaskData: _vm.editTaskData,
          CourseInfo: _vm.CourseInfo,
        },
        on: {
          close: function ($event) {
            _vm.taskDialog = false
            _vm.editTaskData = null
          },
          updateView: _vm.updateView,
        },
      }),
      _c("missed-lesson", {
        attrs: {
          applyCourseData: _vm.applyCourseData,
          show: _vm.applyCourseFlag,
        },
        on: {
          close: function ($event) {
            _vm.applyCourseFlag = false
          },
        },
      }),
      _c("add-courseware", {
        attrs: {
          addMissCourseData: _vm.addMissCourseData,
          show: _vm.dialogaddCourseWare,
        },
        on: {
          close: function ($event) {
            _vm.dialogaddCourseWare = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }