<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <!-- formData：{{formData}} -->
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务描述：" prop="description">
      <Tinymce ref="tinymceref" v-model="formData.description" width="100%" :height="350" />
    </el-form-item>
    <el-form-item label="编码模式：" prop="type">
      <el-radio-group v-model="formData.type">
        <el-radio label="代码">代码</el-radio>
        <el-radio label="演示">演示</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="预置代码：">
      <el-input
        type="textarea"
        placeholder="请输入预置代码"
        :rows="7"
        v-model="formData.code">
      </el-input>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition" v-if="formData.type === '代码'">
      <span>提交代码</span>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition" v-if="formData.type === '演示'">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
    <el-form-item label="默认开放：" prop="defOpen">
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
      <span class="inline-block ml-15">(设置学员任务默认是否开放)</span>
    </el-form-item>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { addTask, editTask } from '@/api/research/curriculum'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { Tinymce },
  data () {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请填写任务内容'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        type: '',
        title: '',
        sourceContent: '',
        completeCondition: 1,
        defOpen: false
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
        description: [
          { required: true, validator: RichTextVal, trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择编码模式', trigger: 'blur' }]
      }
    }
  },
  methods: {
    updateViewData() {
      this.$refs.tinymceref.setContent(this.formData.description)
    },
    addTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          let sourceContent = {
            description: this.formData.description,
            code: this.formData.code,
            type: this.formData.type
          }
          addTask({
            type: 'goc',
            courseUnitId: this.lessonId,
            title: this.formData.title,
            completeCondition: this.formData.type === '代码' ? '提交代码' : this.formData.completeCondition,
            sourceContent: JSON.stringify(sourceContent),
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('添加成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    },

    editTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          let sourceContent = {
            description: this.formData.description,
            code: this.formData.code,
            type: this.formData.type,
            id: this.formData.cid,
            taskId: this.formData.taskId
          }
          editTask({
            id: this.editTaskData.id,
            type: this.editTaskData.type,
            title: this.formData.title, // 标题
            sourceContent: JSON.stringify(sourceContent),
            completeCondition: this.formData.type === '代码' ? '提交代码' : this.formData.completeCondition,
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    }
  }
}
</script>
