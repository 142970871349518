<template>
  <el-row style="padding-bottom: 25px;">
    <h3 class="tab-title">课程计划</h3>
    <!-- curriculumArr：{{curriculumArr}} -->
    <!-- activeNames：{{activeNames}}
    <hr>
    activeNames：{{JSON.stringify(activeNames)}} -->
    <el-row class="task-list">
      <span>总课次：<font class="taskNum" v-text="LessonNum"></font></span>
      <span class="task-span">总任务数：<font class="taskNum" v-text="TaskNum">5</font></span>
      <el-button type="text" icon="el-icon-plus" class="task-right task-cursor" @click="addLesson" v-has="'curriculum:LessonAdd'">添加课</el-button>
      <div class="clearfix"></div>
      <!-- 任务列表 -->
      <draggable
        :reverse="reverse" style="padding-left: 20px;"
        v-model='curriculumArr'
        v-bind="dragOptionsMneu"
        tag='el-collapse'
        :move='getdata'
        @end='datadragEnd'>
        <el-collapse v-for="(item, index) in curriculumArr" :key="index" v-model="activeNames" :id="item.id">
          <el-collapse-item :name="index" @click.native="showTaskList(item, index)">
            <template slot="title">
              <span>第{{ Number(Number(index)+1) }}课：{{item.title}}</span>
              <el-button v-if="item.status === '已启用'" type="success" size="mini" style="margin-left:15px;margin-top: 5px;" @click.stop="gotoMakeStatus(item.id,1,item.title)">已发布</el-button>
              <el-button v-if="item.status === '已禁用'" type="warning" size="mini" style="margin-left:15px;margin-top: 5px;" @click.stop="publishCourseUnit(item.id)">未发布</el-button>
              <div style="margin-left: 15px;">
                <el-button type="text" v-if="item.group !== ''">所属分组:<el-button style="margin-left: 10px;" type="text">{{item.group}}</el-button></el-button>
              </div>
              <div style="flex: 1"></div>
              <el-button type="text" icon="el-icon-plus" class="task-right task-cursor" @click.stop="addTask(item.id)" v-has="'curriculum:TaskAdd'">添加任务</el-button>
              <el-button type="text" class="task-right task-cursor" @click.stop="editLesson(item)" v-has="'curriculum:LessonEdit'">编 辑</el-button>
              <div class="clearfix"></div>
              <el-button type="text" class="task-right task-cursor" @click.stop="delLesson(item.id)" v-has="'curriculum:LessonDel'">删 除</el-button>
              <el-button type="text" class="task-right task-cursor" @click.stop="addMissedLesson(curriculumArr[index])">新增课件视频</el-button>
              <el-button type="text" class="task-right task-cursor" @click.stop="missedLessonManage(curriculumArr[index])">课件视频管理</el-button>
            </template>

            <draggable
              :reverse="reverse" style="padding-left: 20px;"
              v-model='item.children'
              v-bind="dragOptions"
              tag='el-timeline'
              :move='getdata'
              @add="addClass"
              @end='datadragTaskEnd'>
              <el-timeline-item
                class="list-group-item"
                :parentid="activity.courseUnitId"
                :id="activity.id"
                v-for="(activity, index) in item.children"
                :key="index"
                :hide-timestamp="true"
                :timestamp="activity.createTime">
                任务{{index+1}}：{{activity.title}}
                <el-button v-if="activity.status === '已启用'" type="success" size="mini" style="margin-left:15px;" @click.stop="gotoMakeTaskStatus(activity.id,1,activity.title)">已发布</el-button>
                <el-button v-if="activity.status === '已禁用'" type="warning" size="mini" style="margin-left:15px;" @click.stop="publishTask(activity.id)">未发布</el-button>
                <font class="inline-block ml-15" v-if="activity.defOpen">默认开放</font>
                <el-button type="text" class="task-right task-cursor" @click.stop="delTask(activity.id)" v-has="'curriculum:TaskDel'">删 除</el-button>
                <el-button type="text" class="task-right task-cursor" @click.stop="LookTask(activity,activity.id,activity.type)">预 览</el-button>
                <el-button type="text" class="task-right task-cursor" @click.stop="editTask(activity)" v-has="'curriculum:TaskEdit'">编 辑</el-button>
              </el-timeline-item>
            </draggable>
          </el-collapse-item>
        </el-collapse>
      </draggable>

      <!-- 回到顶部 -->
      <el-backtop :bottom="40" :visibility-height="50" target=".task-list"></el-backtop>
    </el-row>
    <!-- 课节 dialog -->
    <add-lesson :show="lessonDialog" @close="lessonDialog=false;editLessonData=null" :editLessonData="editLessonData" @updateView="updateView"></add-lesson>

    <!-- 任务 dialog -->
    <add-task :show="taskDialog" @close="taskDialog=false;editTaskData=null" :lessonId="lessonId" :editTaskData="editTaskData" @updateView="updateView" :CourseInfo="CourseInfo"></add-task>

    <!-- 课件补课视频 dialog -->
    <missed-lesson :applyCourseData="applyCourseData" :show="applyCourseFlag" @close="applyCourseFlag=false"></missed-lesson>

    <!-- 新增课件dialog -->
    <add-courseware :addMissCourseData="addMissCourseData" :show="dialogaddCourseWare" @close="dialogaddCourseWare=false"></add-courseware>
  </el-row>
</template>

<script>
import addLesson from './addLesson'
import addCourseware from './addCourseware'
import missedLesson from './missedLesson'
import addTask from './addTask'
import draggable from 'vuedraggable'
import { delCourseUnit, delTask, dragSort, taskSort, setTaskStatus , setCourseUnitStatus } from '@/api/research/curriculum'
import {getVideoUrl } from '@/api/video/videomanage'
import { getCourseUnitListOnly, getInfo } from '@/api/senate/class'

export default {
  components: {
    draggable,
    addLesson,
    addTask,
    addCourseware,
    missedLesson
  },
  props: {
    curriculumID: {
      default: '',
      type: String
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  computed: {
    dragOptions () {
      return {
        group: 'description',
        ghostClass: 'ghost'
      }
    },
    dragOptionsMneu () {
      return {
        group: 'dragOptionsMneu',
        ghostClass: 'ghost'
      }
    }
  },
  created () {
    this.getCourseUnitListOnly()
  },
  data () {
    return {
      reverse: false,
      lessonDialog: false,
      taskDialog: false,
      editTaskData: null,
      editLessonData: null,
      curriculumArr: [], // 总课节
      originTaskList: this.taskList,
      originCurriculumList: [],
      targetId: '', // 顺序 -> 目标Id
      sourceId: '', // 顺序 -> 来源Id
      targetParentId: '', // 顺序 -> 目标父级Id
      sourceParentId: '', // 顺序 -> 来源父级Id
      lessonId: '', // 课节 ID
      LessonNum: 0, // 总课节-数目
      TaskNum: 0, // 总任务-数目
      courseUnitNum: '', // 当前课节下的任务数
      applyCourseData: {},
      applyCourseFlag: false,
      addMissCourseData: {},
      dialogaddCourseWare: false,
      activeNames: [],
      userIsData: false, // 用户是否要请求数据
    }
  },
  watch: {
    curriculumArr: {
      handler (newVal, oldVal) {
        this.curriculumArr = newVal
      },
      immediate: true,
      deep: true
    },
    activeNames (newVal, oldVal) {
      this.$router.push({
        path: this.$route.path,
        query: {
          curriculumID: this.curriculumID,
          tab: this.$route.query.tab,
          group: JSON.stringify(newVal)
        }
      })
      if (newVal.length > oldVal.length) {
        this.userIsData = true
      } else {
        this.userIsData = false
      }
    },
  },
  methods: {
    async getCourseUnitListOnly () {
      this.TaskNum = 0
      const res1 = await getCourseUnitListOnly({
        pageNum: 1,
        pageSize: 10000,
        courseId: this.curriculumID
      })
      if (!this.$route.query.group || JSON.parse(this.$route.query.group).length === 0) {
        this.curriculumArr = res1.body.list
        this.LessonNum = this.curriculumArr.length
        this.curriculumArr.forEach((ele, idx) => {
          this.TaskNum += Number(ele.taskCount)
        })
      } else {
        this.originCurriculumList = res1.body.list
        this.getGroupData(res1) // 获取用户已打开标签子数据
      }
    },

    getGroupData (res) {
      res.body.list.forEach((ele, idx) => {
        JSON.parse(this.$route.query.group).forEach((item, index) => {
          if (Number(idx) === Number(item)) {
            this.setTaskList(ele, index)
          }
        })
      })
    },

    setTaskList (row, idx) {
      getInfo({
        id: row.id,
        onlyEnable: false
      }).then(res => {
        if (res.state === 'success') {
          this.originCurriculumList.forEach((ele, idx) => {
            if (ele.id === res.body.id) {
              if (!this.originCurriculumList[idx].children) {
                this.originCurriculumList[idx].children = res.body.taskList
              }
            }
          })
          if (JSON.parse(this.$route.query.group).length === Number(idx + 1)) {
            this.curriculumArr = [...this.originCurriculumList]
            this.LessonNum = this.curriculumArr.length
            this.curriculumArr.forEach((ele, idx) => {
              this.TaskNum += Number(ele.taskCount)
            })
            setTimeout(()=> {
              this.activeNames = JSON.parse(this.$route.query.group)
            }, 300)
          }
        }
      })
    },

    showTaskList (row, idx) {
      if (this.userIsData) {
        getInfo({
          id: row.id,
          onlyEnable: false
        }).then(res => {
          if (res.state === 'success') {
            this.curriculumArr.forEach((ele, idx) => {
              if (ele.id === res.body.id) {
                if (!this.curriculumArr[idx].children) {
                  this.curriculumArr[idx].children = res.body.taskList
                  this.$forceUpdate()
                }
              }
            })
            this.originCurriculumList = [...this.curriculumArr]
          }
        })
      }
    },

    gotoMakeStatus(id,type,name) {
      let stringTips = ""
      if(type === 1) {
        stringTips = '是否撤销发布，撤销发布后用户可不见'
      }else {
        stringTips = '发布<<'+name+'>>课节, 是否继续?'
      }

      this.$confirm(stringTips, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(type === 1) {
          this.unPublishCourseUnit(id)
        }else {
          this.publishCourseUnit(id)
        }
      }).catch(() => {

      });
    },
    // 发布课节
    publishCourseUnit (id) {
      setCourseUnitStatus({
        ids: [id],
        status: '已启用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },

    // 取消发布课节
    unPublishCourseUnit (id) {
      setCourseUnitStatus({
        ids: [id],
        status: '已禁用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },
    gotoMakeTaskStatus(id,type,name) {
      let stringTips = ""
      if(type === 1) {
        stringTips = '是否撤销发布，撤销发布后用户可不见'
      }else {
        stringTips = '发布<<'+name+'>>任务, 是否继续?'
      }

      this.$confirm(stringTips, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(type === 1) {
          this.unPublishTask(id)
        }else {
          this.publishTask(id)
        }
      }).catch(() => {

      });
    },
    // 发布任务
    publishTask (id) {
      setTaskStatus({
        ids: [id],
        status: '已启用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },

    // 取消发布任务
    unPublishTask (id) {
      setTaskStatus({
        ids: [id],
        status: '已禁用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('操作成功')
          this.updateView()
        } else {
          $msg(res.errMsg, 2)
        }
      })
    },
    missedLessonManage (row) {
      this.applyCourseData = row
      this.applyCourseFlag = true
    },
    addMissedLesson (row) {
      this.addMissCourseData = row
      this.dialogaddCourseWare = true
    },
    // 添加课
    addLesson () {
      this.lessonDialog = true
    },

    // 编辑课
    editLesson (item) {
      this.editLessonData = item
      this.lessonDialog = true
    },

    // 添加任务
    addTask (id) { // 函数参数为 课节id
      this.lessonId = id // 课节id
      this.taskDialog = true
    },

    // 预览
    LookTask (activity,taskID,type) {
      if(type === "视频") {
        this.getVideoUrl(activity.sourceId)
      }else {
        let routeData = null
        if(type === "pdf") {
          routeData = this.$router.resolve({
            path: '/research/pdfpreview',
            query: {
              curriculumID: this.curriculumID, // 课程id
              taskID:taskID // 任务id
            },
          })
        } else {
          routeData = this.$router.resolve({
            path: '/research/preview-task',
            query: {
              curriculumID: this.curriculumID, // 课程id
              taskID // 任务id
            },
            params: { id: this.id }
          })
        }
        window.open(routeData.href, '_blank')
      }
    },
    async getVideoUrl (fileId) {
      const res = await getVideoUrl({
        fileId:fileId
      })
      if(res.code === 1) {
        $msg(res.msg,2)
      }else if(res.code === 0 ) {
        let routeData = this.$router.resolve({
          path: '/research/preview-video',
          query: {
            pathUrl: res.data
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    // 编辑任务
    editTask (row) {
      this.lessonId = row.courseUnitId // 课节Id
      this.editTaskData = row
      this.taskDialog = true
    },

    // 删除课节
    delLesson (id) {
      this.$confirm('确定删除？已发布的课程删除课或任务后，已购课学员将无法查看该课或任务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delCourseUnit({
            ids: [id]
          })
          window.$msg('删除成功')
          this.getCourseUnitListOnly()
        })
        .catch(() => {
          return false
        })
    },

    // 删除任务
    delTask (id) {
      this.$confirm('确定删除？已发布的课程删除课或任务后，已购课学员将无法查看该课或任务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTask({
            ids: [id]
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('删除成功')
              this.getCourseUnitListOnly()
            }
          })
        })
        .catch(() => {
          return false
        })
    },

    // 拖动中与拖动结束
    getdata ({ dragged, related }) {
      this.sourceId = dragged.getAttribute('id')
      this.targetId = related.getAttribute('id')
      this.sourceParentId = dragged.getAttribute('parentid')
      this.targetParentId = related.getAttribute('parentid')
    },

    // 课节拖拽结束
    datadragEnd (evt) {
      if (evt.oldIndex !== evt.newIndex) {
        dragSort({
          id: this.sourceId,
          toId: this.targetId
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('操作成功')
            this.updateView()
          }
        })
      }
    },

    // 拖拽 add 事件
    addClass (evt) {
      this.targetParentId = evt.path[4].getAttribute('id')
    },

    // 任务-子项拖拽排序
    datadragTaskEnd (evt) {
      if (this.sourceParentId !== this.targetParentId) {
        taskSort({
          id: this.sourceId,
          sort: evt.newIndex + 1,
          courseUnitId: this.targetParentId
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('操作成功')
            this.updateView()
          }
        })
      } else {
        if (evt.oldIndex !== evt.newIndex) {
          let sort = 0
          if (evt.newIndex === 0) {
            sort = evt.newIndex + 0.5
          } else {
            sort = evt.newIndex + 1.5
          }
          taskSort({
            id: this.sourceId,
            sort: sort,
            courseUnitId: this.targetParentId
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('操作成功')
              this.updateView()
            }
          })
        } else {
          return false
        }
      }
    },

    // 编辑成功，更新视图
    updateView () {
      // this.userIsData = false
      // this.activeNames = []
      this.getCourseUnitListOnly()
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-title {
  padding-left: 35px;
  margin: 20px 0;
  font-size: 17px;
  color: #464646;
  font-weight: 600;
}
.tab-input {
  width: 350px;
}
/deep/ .el-form-item {
  margin-bottom: 20px;
}

.task-list {
  width: 80%;
  box-sizing: border-box;
  padding-left: 25px;
  span {
    float: left;
    line-height: 40px;
    font-size: 16px;
    color: #000000;
  }
  .task-span {
    margin-left: 15px;
  }
  .taskNum {
    color: #f5c319;
  }
  .task-left {
    float: left;
  }
  .task-right {
    float: right;
    margin-left: 10px;
  }
  .task-cursor {
    cursor: pointer;
  }
}

.list-group-item {
  cursor: move;
}

.ghost {
  border: 1px solid #aaaaaa;
}

/deep/ .el-timeline-item {
  padding: 15px 0;
}
/deep/ .el-timeline {
  margin: 15px 0;
}

.scrollTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  background: #f5c319;
  border-radius: 50%;
}

/deep/ .el-icon-arrow-right:before {
  content: '';
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}
</style>
