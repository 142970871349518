<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    width="60%"
    top="7vh"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="taskTitle"></span>
    </div>
    <!-- 步骤条 -->
    <el-steps :active="active" align-center finish-status="success" simple>
      <el-step title="选择任务类型"></el-step>
      <el-step title="设置任务内容"></el-step>
    </el-steps>

    <!-- 任务类型 -->
    <el-row :gutter="20" class="task-list" v-show="taskType">
      <el-col :span="6" @click.native="seleTask(1)">
        <div class="task-item">
          <img src="../../../assets/images/tuwen.png" />
          <p>图文</p>
        </div>
      </el-col>
      <el-col :span="6" @click.native="seleTask(2)">
        <div class="task-item">
          <img src="../../../assets/images/wailian.png" />
          <p>外链</p>
        </div>
      </el-col>
      <el-col :span="6" @click.native="seleTask(5)">
        <div class="task-item">
          <img src="../../../assets/images/wailian.png" />
          <p>python</p>
        </div>
      </el-col>
      <el-col :span="6" @click.native="seleTask(3)">
        <div class="task-item">
          <img src="../../../assets/images/kaoshi.png" />
          <!-- <p>考试</p> -->
          <p>测试</p>
        </div>
      </el-col>

      <el-col :span="6" style="margin-top:20px;" @click.native="seleTask(4)">
        <div class="task-item">
          <img src="../../../assets/images/bianchen.png" />
          <p>编程</p>
        </div>
      </el-col>
      <el-col :span="6"  style="margin-top:20px;"  @click.native="seleTask(6)">
        <div class="task-item">
          <img src="../../../assets/images/kaoshi.png" />
          <p>PDF</p>
        </div>
      </el-col>
      <el-col :span="6"  style="margin-top:20px;"  @click.native="seleTask(7)">
        <div class="task-item">
          <img src="../../../assets/images/wailian.png" />
          <p>视频</p>
        </div>
      </el-col>
      <el-col :span="6" style="margin-top:20px;"  @click.native="seleTask(8)">
        <div class="task-item">
          <img src="../../../assets/images/tuwen.png" />
          <p>文件</p>
        </div>
      </el-col>
      <el-col :span="6" style="margin-top:20px;"  @click.native="seleTask(9)">
        <div class="task-item">
          <img src="../../../assets/images/tuwen.png" />
          <p>goc</p>
        </div>
      </el-col>
      <el-col :span="6" style="margin-top:20px;"  @click.native="seleTask(10)">
        <div class="task-item">
          <img src="../../../assets/images/kaoshi.png" />
          <p>scratch</p>
        </div>
      </el-col>
    </el-row>

    <!-- 图文 -->
    <el-row v-if="taskTypeNum === 1" style="margin-top: 55px;">
      <image-text ref="imageText" :lessonId="lessonId"  type='2' :editTaskData="editTaskData" @updateTask="updateTask"></image-text>
    </el-row>

    <!-- 文件 -->
    <el-row v-if="taskTypeNum === 8" style="margin-top: 55px;">
      <file-text ref="fileText" :lessonId="lessonId"  type='8' :editTaskData="editTaskData" @updateTask="updateTask"></file-text>
    </el-row>
    <!-- scratch -->
    <el-row v-if="taskTypeNum === 10" style="margin-top: 55px;">
      <scratch-text ref="scratchText" :lessonId="lessonId"  type='10' :editTaskData="editTaskData" @updateTask="updateTask"></scratch-text>
    </el-row>
    <!-- 外部链接 -->
    <el-row v-if="taskTypeNum === 2" style="margin-top: 55px;">
      <outside-link ref="outsideLink" :lessonId="lessonId"  :editTaskData="editTaskData" @updateTask="updateTask"></outside-link>
    </el-row>

    <!-- pdf任务 -->
    <el-row v-if="taskTypeNum === 6" style="margin-top: 55px;">
      <pdf-task ref="pdftask" :lessonId="lessonId"  :editTaskData="editTaskData" @updateTask="updateTask"></pdf-task>
    </el-row>

    <!-- 视频任务 -->
    <el-row v-if="taskTypeNum === 7" style="margin-top: 55px;">
      <video-task ref="videotask" :headers="headers" :lessonId="lessonId" :editTaskData="editTaskData" @updateTask="updateTask"></video-task>
    </el-row>

    <!-- goc任务 -->
    <el-row v-if="taskTypeNum === 9" style="margin-top: 55px;">
      <goc-task ref="goctask" :lessonId="lessonId" :editTaskData="editTaskData" @updateTask="updateTask"></goc-task>
    </el-row>

    <!-- python外部链接 -->
    <el-row v-if="taskTypeNum === 5" style="margin-top: 55px;">
      <!--<outside-link ref="outsideLink" :lessonId="lessonId" type="5" :editTaskData="editTaskData" @updateTask="updateTask"></outside-link>-->
      <image-text ref="imageText" :lessonId="lessonId"  type='5' :editTaskData="editTaskData" @updateTask="updateTask"></image-text>
    </el-row>

    <!-- 试卷 -->
    <el-row v-if="taskTypeNum === 3" style="margin-top: 55px;">
      <exam-test ref="examTest" :lessonId="lessonId" :editTaskData="editTaskData" :CourseInfo="CourseInfo" @updateTask="updateTask" @getExamInfo="getExamInfo"></exam-test>
    </el-row>

    <!-- 编程题库 -->
    <el-row v-if="taskTypeNum === 4" style="margin-top: 55px;">
      <edit-code ref="editCode" :lessonId="lessonId" :editTaskData="editTaskData" :CourseInfo="CourseInfo" :taskTitle="taskTitle" @updateTask="updateTask"></edit-code>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" v-if="seleTaskBtns">
      <template v-if="!editTaskData">
        <el-button size="small" @click="prev" v-if="active>1">上一步</el-button>
        <el-button size="small" @click="next" v-else>下一步</el-button>
        <el-button size="small" type="primary" @click="saveTask">保存</el-button>
      </template>
      <template v-else>
        <el-button size="small" type="primary" @click="saveTask">保存</el-button>
      </template>
    </el-row>

  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute taskTitle 根据有无 taskTitle数据 动态渲染 dialog弹出框 数据
 * @attribute lessonId 课节 ID
 * @function close 自定义弹窗关闭事件
 */

import ImageText from './components/image-text' // 图文任务
import FileText from './components/file-text' // 图文任务
import OutsideLink from './components/outside-link' // 外联任务
import PdfTask from './components/pdf-task' // pdf 任务
import VideoTask from './components/video-task' // 视频 任务
import ExamTest from './components/exam-test' // 试卷任务
import EditCode from './components/edit-code' // 编程任务
import gocTask from './components/goc-task' // goc任务
import { getToken } from '@/utils/auth'
import { getTaskInfo,getUploadTaskInfo } from '@/api/research/curriculum'
import { getExamInfo } from '@/api/research/exam'
import ScratchText from "./components/scratch-text";

export default {
  components: {
    ScratchText,
    PdfTask,
    ImageText,
    OutsideLink,
    ExamTest,
    EditCode,
    VideoTask,
    FileText,
    gocTask
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    lessonId: {
      default: '',
      type: String
    },
    editTaskData: {
      default: () => {
        return {}
      },
      type: Object
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      headers:{},
      taskTitle: '',
      active: 0,
      seleTaskBtns: false, // 底部按钮状态
      taskType: true, // 任务选择类型状态
      taskTypeNum: 0, // 任务类型 1-图文、2-外部链接、3-考试、4-编程题库 5-python外部链接 ,6-pdf ,7-视频
      dataObject: null
    }
  },
  methods: {
    openDialog () {
      this.headers.Authorization = getToken()
      // 重置表单项
      // this.formData = {
      //   title: '',
      //   sourceContent: '',
      //   completeCondition: 1
      // }
      // this.testDada = []
      // this.dataObject = null
      if (this.editTaskData !== null) {
        this.taskTitle = '编辑任务'
        this.taskType = false
        this.seleTaskBtns = true
        this.active = 2
        switch (this.editTaskData.type) {
          case '图文':
            this.taskTypeNum = 1
            break
          case '外链':
            this.taskTypeNum = 2
            break
          case '测试':
            this.taskTypeNum = 3
            break
          case '编程':
            this.taskTypeNum = 4
            break
          case 'python':
            this.taskTypeNum = 5
            break
          case 'pdf':
            this.taskTypeNum = 6
            break
          case '视频':
            this.taskTypeNum = 7
            break
          case '上传':
            this.taskTypeNum = 8
            break
          case 'goc':
            this.taskTypeNum = 9
            break
          case 'scratch':
            this.taskTypeNum = 10
            break
        }
        this.getTaskInfo(this.taskTypeNum)
      } else {
        this.taskTypeNum = 0
        this.taskType = true
        this.seleTaskBtns = false
        this.active = 1
        this.taskTitle = '添加任务'
      }
    },

    // 添加\更新任务 成功回调
    updateTask () {
      this.$emit('close')
      this.$emit('updateView')
    },

    // 预览试卷
    previewPaper (row) {
      this.close()
      this.$router.push({
        path: '/admin/previewPaper/',
        query: {
          id: row.id
        }
      })
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 下一步
    next () {
      if (this.active++ > 1) this.active = 1
    },

    // 上一步
    prev (status) {
      // 重置表单项
      this.formData = {
        title: '',
        sourceContent: '',
        completeCondition: 1
      }
      if (status) {
        this.taskTypeNum = 0
        this.taskType = true
        this.seleTaskBtns = false
        if (this.active-- < 1) this.active = 1
      } else {
        if (this.active-- < 1) this.active = 1
      }
    },

    // 选择任务
    seleTask (status) {
      this.seleTaskBtns = true
      this.taskType = false
      this.taskTypeNum = status
      this.next()
    },

    // 获取任务详细信息
    getTaskInfo (taskTypeNum) {
      getTaskInfo({
        id: this.editTaskData.id
      }).then(res => {
        if (res.state === 'success') {
          switch (taskTypeNum) {
            case 1:
              this.$refs.imageText.formData = { ...res.body }
              this.$refs.imageText.updateViewData()
              break
            case 2:
              this.$refs.outsideLink.formData = { ...res.body }
              break
            case 3:
              this.$refs.examTest.formData = { ...res.body }
              this.getExamInfo(res.body.sourceId) // 获取试卷详细信息
              break
            case 4:
              this.$refs.editCode.formData = { ...res.body }
              // this.$refs.editCode.queryQuesById(res.body.sourceId)
              break
            case 5:
              this.$refs.imageText.formData = { ...res.body }
              this.$refs.imageText.updateViewData()
              break
            case 6:
              this.$refs.pdftask.formData = { ...res.body }
              break
            case 7:
              this.$refs.videotask.formData = { ...res.body }
              break
            case 8:
              this.$refs.fileText.formData = { ...res.body }
              this.getUploadTaskInfo()
              this.$refs.fileText.updateViewData()
              break
            case 9:
              let a = JSON.parse(res.body.sourceContent)
              this.$refs.goctask.formData =
              {
                ...res.body,
                description: a.description,
                code: a.code,
                type: a.type,
                cid: a.id,
                taskId: a.taskId
              }
              this.$refs.goctask.updateViewData()
              break
            case 10:
              this.$refs.scratchText.formData = { ...res.body }
              this.$refs.scratchText.updateViewData()
              break
          }
        }
      })
    },

    async getUploadTaskInfo() {
      const response =  await getUploadTaskInfo({
        taskId:this.editTaskData.id
      })
      this.$refs.fileText.formData.minFileSize = response.body.minSize
      this.$refs.fileText.formData.maxFileSize = response.body.maxSize
      this.$refs.fileText.formData.uploadType = JSON.parse(response.body.extList)
    },

    getExamInfo (id) {
      getExamInfo({ id }).then(res => {
        if (res.state === 'success') {
          this.$refs.examTest.testDada = []
          this.$refs.examTest.testDada.push(res.body)
        }
      })
    },

    // 保存任务
    saveTask () {
      if (this.editTaskData !== null) { // 编辑任务
        switch (this.taskTypeNum) {
          case 1:
            this.$refs.imageText.editTask()
            break
          case 2:
            this.$refs.outsideLink.editTask(2)
            break
          case 3:
            this.$refs.examTest.editTask()
            break
          case 4:
            this.$refs.editCode.editTask()
            break
          case 5:
            this.$refs.imageText.editTask(5)
            break
          case 6:
            this.$refs.pdftask.editTask()
            break
          case 7:
            this.$refs.videotask.editTask()
            break
          case 8:
            this.$refs.fileText.editTask()
            break
          case 9:
            this.$refs.goctask.editTask()
            break
          case 10:
            this.$refs.scratchText.editTask()
            break
        }
      } else { // 添加任务
        switch (this.taskTypeNum) {
          case 1:
            this.$refs.imageText.addTask()
            break
          case 2:
            this.$refs.outsideLink.addTask()
            break
          case 3:
            this.$refs.examTest.addTask()
            break
          case 4:
            this.$refs.editCode.addTask()
            break
          case 5:
            this.$refs.imageText.addTask()
            break
          case 6:
            this.$refs.pdftask.addTask()
            break
          case 7:
            this.$refs.videotask.addTask()
            break
          case 8:
            this.$refs.fileText.addTask()
            break
          case 9:
            this.$refs.goctask.addTask()
            break
          case 10:
            this.$refs.scratchText.addTask()
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
.task-list {
  margin: 35px 0;
}
.task-item {
  display: flex;
  flex-direction: column;
  height: 150px;
  background: #f4f6f8;
  border-radius: 4px;
  border: 3px solid #f4f6f8;
  box-sizing: border-box;
  padding-top: 28px;
  align-items: center;
  cursor: pointer;
  &:hover {
    border: 3px solid #f5c319;
  }
  img {
    width: 32px;
    height: 32px;
  }
  p {
    font-size: 16px;
    margin-top: 25px;
  }
}

/deep/ .el-dialog {
  min-width: 888px;
}

/deep/ .el-dialog__footer {
  text-align: right;
}

/deep/ .el-table td {
  padding: 15px 0 !important;
}
/deep/ .el-table th {
  padding: 15px 0 !important;
  background: #fcfdff;
}
/deep/ .el-table th > .cell {
  font-size: 15px !important;
  font-weight: 400;
  color: #434343;
}
/deep/ .el-table .cell {
  font-size: 15px !important;
  font-weight: 400;
  color: #666;
}
</style>
