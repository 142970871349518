var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务内容：", prop: "sourceContent" } },
        [
          _c("Tinymce", {
            attrs: { tinymceref: "", width: "100%", height: 350 },
            model: {
              value: _vm.formData.sourceContent,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "sourceContent", $$v)
              },
              expression: "formData.sourceContent",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "上传类型：", prop: "uploadType" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "250px" },
              attrs: {
                multiple: "",
                "collapse-tags": "",
                placeholder: "请选择允许的上传类型",
              },
              on: { change: _vm.forceUpdate },
              model: {
                value: _vm.formData.uploadType,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "uploadType", $$v)
                },
                expression: "formData.uploadType",
              },
            },
            _vm._l(_vm.uploadTypeArray, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "限制(单位KB)：",
            required: "",
            "label-width": "150px",
          },
        },
        [
          _vm._v(" 最小值 "),
          _c("el-input-number", {
            staticStyle: { "margin-left": "20px", "margin-right": "20px" },
            attrs: { min: 0 },
            model: {
              value: _vm.formData.minFileSize,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "minFileSize", $$v)
              },
              expression: "formData.minFileSize",
            },
          }),
          _vm._v(" 最大值 "),
          _c("el-input-number", {
            staticStyle: { "margin-left": "20px" },
            attrs: { max: 2147483647 },
            model: {
              value: _vm.formData.maxFileSize,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "maxFileSize", $$v)
              },
              expression: "formData.maxFileSize",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [_c("span", [_vm._v("完成上传作品")])]
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认开放：", prop: "defOpen" } },
        [
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-text": "开",
              "inactive-text": "关",
            },
            model: {
              value: _vm.formData.defOpen,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "defOpen", $$v)
              },
              expression: "formData.defOpen",
            },
          }),
          _c("span", { staticClass: "inline-block ml-15" }, [
            _vm._v("(设置学员任务默认是否开放)"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }