var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务内容：", prop: "sourceContent" } },
        [
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                "margin-right": "25px",
                cursor: "pointer",
              },
              on: { click: _vm.addTestPaper },
            },
            [
              _c("i", { staticClass: "el-icon-circle-plus" }),
              _vm._v(" 新建试卷 "),
            ]
          ),
          _c(
            "span",
            {
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.seleTestPaper },
            },
            [
              _c("i", { staticClass: "el-icon-s-operation" }),
              _vm._v(" 选择试卷 "),
            ]
          ),
          _c(
            "el-table",
            {
              staticStyle: {
                "border-top": "1px solid #EBEEF5",
                "border-left": "1px solid #EBEEF5",
                "margin-top": "25px",
                width: "70%",
              },
              attrs: {
                data: _vm.testDada,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "已选试卷" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "title-link",
                            on: {
                              click: function ($event) {
                                return _vm.previewPaper(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.title))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editPaper(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delPaper(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.CourseInfo.type === "刷题"
        ? _c(
            "el-form-item",
            { attrs: { label: "知识点描述：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写知识点描述" },
                model: {
                  value: _vm.formData.knowledge,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "knowledge",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.knowledge",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("el-form-item", { attrs: { label: "完成条件：" } }, [
        _c("span", [_vm._v("交卷")]),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "默认开放：" } },
        [
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-text": "开",
              "inactive-text": "关",
            },
            model: {
              value: _vm.formData.defOpen,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "defOpen", $$v)
              },
              expression: "formData.defOpen",
            },
          }),
          _c("span", { staticClass: "inline-block ml-15" }, [
            _vm._v("(设置学员任务默认是否开放)"),
          ]),
        ],
        1
      ),
      _c("add-paper", {
        attrs: {
          show: _vm.paperDialog,
          isPublish: true,
          editPaperData: _vm.editPaperData,
        },
        on: {
          close: function ($event) {
            _vm.paperDialog = false
            _vm.editPaperData = null
          },
          addData: _vm.addData,
          editData: _vm.editData,
        },
      }),
      _c("manage-paper", {
        attrs: { show: _vm.managePaperDialog },
        on: {
          close: function ($event) {
            _vm.managePaperDialog = false
          },
          savePaper: _vm.savePaper,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }