var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("课件视频管理")])]
      ),
      _c(
        "el-row",
        { staticClass: "mb-20" },
        [
          _c(
            "el-select",
            {
              staticClass: "mr-10",
              attrs: { placeholder: "请选择附件类型" },
              on: { change: _vm.search },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            },
            _vm._l(_vm.typeOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c("el-button", {
            attrs: { type: "primary", size: "medium" },
            domProps: { textContent: _vm._s(_vm.SortText) },
            on: { click: _vm.mySort },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "dragTable",
          attrs: {
            data: _vm.coursewareList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": "id",
          },
        },
        [
          _vm.isSort
            ? _c(
                "el-table-column",
                { attrs: { label: "排序", align: "center", width: "60" } },
                [_c("span", { staticClass: "el-icon-rank" })]
              )
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "名称", align: "center", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { label: "类型", align: "center", prop: "type" },
          }),
          _c("el-table-column", {
            attrs: { label: "创建日期", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.createTime.substring(
                            0,
                            scope.row.createTime.length - 5
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.status))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.viewCourseWare(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteCourseWare(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _vm.isSort
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmSort } },
                [_vm._v("确定排序")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }