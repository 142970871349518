import { render, staticRenderFns } from "./video-task.vue?vue&type=template&id=237094f4"
import script from "./video-task.vue?vue&type=script&lang=js"
export * from "./video-task.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_business_lcPT/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('237094f4')) {
      api.createRecord('237094f4', component.options)
    } else {
      api.reload('237094f4', component.options)
    }
    module.hot.accept("./video-task.vue?vue&type=template&id=237094f4", function () {
      api.rerender('237094f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/research/curriculum-manage/components/video-task.vue"
export default component.exports