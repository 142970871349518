<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false">
    <div slot="title"
        class="dia-tit">
      <i></i>
      <span v-text="lessonTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="课名称：" prop="title">
        <el-input v-model="formData.title" placeholder="请填写课名称"></el-input>
      </el-form-item>
      <el-form-item label="课分组：">
        <el-input v-model="formData.group" placeholder="请填写课分组"></el-input>
      </el-form-item>
    </el-form>
    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="saveLesson()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editLessonData 根据有无 editLessonData 动态渲染 dialog弹出框 数据
 * @function close 自定义弹窗关闭事件
 */
import { addCourseUnit, editCourseUnit } from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editLessonData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },

  data () {
    return {
      curriculumID: '', // 课程id
      lessonTitle: '',
      formData: {},
      rules: {
        title: [{ required: true, message: '请填写课名称', trigger: 'blur' }]
      },
      fileData: {},
      fileList: []
    }
  },
  created () {
    this.curriculumID = this.$route.query.curriculumID
  },
  methods: {
    openDialog () {
      this.formData = {}
      if (this.editLessonData !== null) {
        this.lessonTitle = '编辑课'
        this.formData = { ...this.editLessonData }
      } else {
        this.lessonTitle = '新增课'
      }
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 添加课
    saveLesson () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.editLessonData !== null) {
            editCourseUnit({
              ...this.formData
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView') // 更新视图
              }
            })
          } else {
            addCourseUnit({
              courseId: this.curriculumID,
              ...this.formData
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('添加成功')
                this.close()
                this.$emit('updateView') // 更新视图
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
