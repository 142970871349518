<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <!-- editTaskData：{{editTaskData}}
    <hr>
    testDada：{{testDada}} -->
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务内容：" prop="sourceContent">
      <span
        style="display:inline-block; margin-right: 25px;cursor: pointer;"
        @click="addTestPaper"
      >
        <i class="el-icon-circle-plus"></i>
        新建试卷
      </span>
      <span style="cursor: pointer;" @click="seleTestPaper">
        <i class="el-icon-s-operation"></i>
        选择试卷
      </span>
      <el-table
        style="border-top: 1px solid #EBEEF5;border-left: 1px solid #EBEEF5;margin-top: 25px;width: 70%;"
        :data="testDada"
        border
        fit
        highlight-current-row
      >
        <el-table-column align="center" label="已选试卷">
          <template slot-scope="scope">
            <span @click="previewPaper(scope.row)" class="title-link">{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editPaper(scope.row)">编辑</el-button>
            <el-button type="text" @click="delPaper(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item label="知识点描述：" v-if="CourseInfo.type === '刷题'">
      <el-input v-model.trim="formData.knowledge" placeholder="请填写知识点描述"></el-input>
    </el-form-item>
    <el-form-item label="完成条件：">
      <span>交卷</span>
    </el-form-item>
    <el-form-item label="默认开放：">
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
      <span class="inline-block ml-15">(设置学员任务默认是否开放)</span>
    </el-form-item>

    <!-- addPaper -->
    <add-paper
      :show="paperDialog"
      @close="paperDialog=false;editPaperData=null;"
      :isPublish="true"
      :editPaperData="editPaperData"
      @addData="addData"
      @editData="editData"
    ></add-paper>

    <manage-paper
      :show="managePaperDialog"
      @close="managePaperDialog=false"
      @savePaper="savePaper"
    ></manage-paper>
  </el-form>
</template>

<script>
import AddPaper from '@/components/research/exam-manage/addPaper'
import ManagePaper from './manage-paper'
import { addExam, editExam } from '@/api/research/exam'
import { addTask, editTask } from '@/api/research/curriculum'

export default {
  components: {
    AddPaper,
    ManagePaper
  },
  watch: {
    editTaskData: {
      handler (newVal, oldVal) {
        this.testDada = []
      }
    }
  },
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    let RichPaperVal = (rule, value, callback) => {
      if (this.testDada.length === 0) {
        callback(new Error('请选择试卷'))
      } else {
        callback()
      }
    }
    return {
      formData: {
        title: '',
        defOpen: false
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
        sourceContent: [
          { required: true, validator: RichPaperVal, trigger: 'blur' }
        ]
      },
      paperDialog: false,
      managePaperDialog: false,
      editPaperData: null,
      testDada: []
    }
  },
  methods: {
    // 新增试卷
    addTestPaper () {
      this.paperDialog = true
    },

    // 选择试卷
    seleTestPaper () {
      this.managePaperDialog = true
    },

    // 启用试卷
    publicPaper (id) {
      editExam({
        id,
        status: '已发布'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('启用成功')
        }
      })
    },

    // 编辑试卷
    editPaper(row) {
      this.editPaperData = row
      this.paperDialog = true
    },

    // 删除试卷
    delPaper (row) {
      this.testDada.splice(0, 1)
    },

    addData (data, status) {
      let nary = []
      data.questionList.forEach(element => {
        nary.push(element.questionId)
      })
      let newNary = Array.from(new Set(nary))
      if (nary.length !== newNary.length) {
        window.$msg('试卷中不允许存在相同试题', 2)
      } else {
        addExam({
          title: data.title,
          limitTime: data.limitTime,
          autoResult: data.autoResult,
          isRepeatable: data.isRepeatable,
          isShowScore: data.isShowScore,
          questionList: data.questionList,
          catalogId: data.catalogId[data.catalogId.length - 1]
          // ...data
        }).then(res => {
          if (res.state === 'success') {
            if (status === true) {
              this.$confirm('是否发布当前试卷,发布成功后试卷不可修改', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  this.publicPaper(res.body)
                })
                .catch(() => {
                  return false
                })
            }
            window.$msg('添加成功')
            this.paperDialog = false
            this.editPaperData = null
          }
        })
      }
    },

    editData (data) {
      let nary = []
      data.questionList.forEach(element => {
        nary.push(element.questionId)
      })
      let newNary = Array.from(new Set(nary))
      if (nary.length !== newNary.length) {
        window.$msg('试卷中不允许存在相同试题', 2)
      } else {
        editExam({
          id: data.id,
          title: data.title,
          limitTime: data.limitTime,
          autoResult: data.autoResult,
          isRepeatable: data.isRepeatable,
          isShowScore: data.isShowScore,
          questionList: data.questionList,
          catalogId: typeof (data.catalogId) === 'string' ? data.catalogId : data.catalogId[data.catalogId.length - 1],
          // ...data
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('编辑成功')
            this.$emit('getExamInfo', this.editTaskData.sourceId)
            this.paperDialog = false
            this.editPaperData = null
          }
        })
      }
    },

    // 保存试卷
    savePaper (data) {
      this.testDada = Object.assign([], data)
    },

    addTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          addTask({
            type: '测试',
            courseUnitId: this.lessonId,
            title: this.formData.title,
            // completeCondition: '交卷', // 任务完成条件
            sourceContent: this.testDada[0].id,
            defOpen: this.formData.defOpen,
            knowledge: this.formData.knowledge,
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('添加成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    },

    editTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editTask({
            id: this.editTaskData.id, // 任务id
            title: this.formData.title,
            sourceId: this.editTaskData.sourceId === this.testDada[0].id ? null : this.testDada[0].id,
            completeCondition: '提交试卷',
            defOpen: this.formData.defOpen,
            knowledge: this.formData.knowledge,
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('添加成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    },

    previewPaper (row) {
      this.$emit('close')
      let routeUrl = this.$router.resolve({
        path: '/research/exam-details',
        query: { examID: row.id }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
