var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        width: "70%",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("课程管理新增学员")])]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "全部学员", name: "allList" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "input-with-select",
                                      attrs: {
                                        placeholder: "请输入内容",
                                        clearable: "",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.inputVal,
                                        callback: function ($$v) {
                                          _vm.inputVal = $$v
                                        },
                                        expression: "inputVal",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            slot: "prepend",
                                            placeholder: "请选择",
                                          },
                                          slot: "prepend",
                                          model: {
                                            value: _vm.select,
                                            callback: function ($$v) {
                                              _vm.select = $$v
                                            },
                                            expression: "select",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "用户名",
                                              value: "1",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "姓名",
                                              value: "2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: { click: _vm.search },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-table",
                                {
                                  ref: "tableData",
                                  staticStyle: { "margin-top": "25px" },
                                  attrs: {
                                    data: _vm.dataList,
                                    border: "",
                                    fit: "",
                                    "row-key": _vm.getRowKeys,
                                    "highlight-current-row": "",
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      align: "center",
                                      "reserve-selection": true,
                                      width: "55",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "用户名",
                                      align: "center",
                                      prop: "loginName",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "姓名",
                                      align: "center",
                                      prop: "name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("el-pagination", {
                                attrs: {
                                  "current-page": _vm.pagination.currentPage,
                                  "page-sizes": _vm.pagination.pageSizes,
                                  "page-size": _vm.pagination.pageSize,
                                  layout: _vm.pagination.layout,
                                  total: this.pagination.total,
                                },
                                on: {
                                  "size-change": _vm.handleSizeChange,
                                  "current-change": _vm.handleCurrentChange,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "批量添加", name: "batchAdd" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder:
                                    "S0001\nS0002\nS0003\n……\n……\n请按每行一个学号的格式填写要添加的学员，点击导入按钮",
                                  rows: "8",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.batchStr,
                                  callback: function ($$v) {
                                    _vm.batchStr = $$v
                                  },
                                  expression: "batchStr",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.importFun },
                                },
                                [_vm._v("导入")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "box-shadow": "0px 2px 9px 0px rgba(0, 0, 0, 0.15)",
                        "padding-bottom": "30px",
                      },
                    },
                    [
                      _c("el-row", { staticClass: "seleRow" }, [
                        _c("span", [_vm._v("已选择：")]),
                        _c("span", {
                          staticClass: "seleNum",
                          domProps: {
                            textContent: _vm._s(
                              _vm.multipleSelectionTotal.length
                            ),
                          },
                        }),
                      ]),
                      _vm._l(
                        _vm.multipleSelectionTotal,
                        function (item, index) {
                          return _c(
                            "ul",
                            { key: index, staticClass: "seleList" },
                            [
                              _c("li", { staticClass: "sele-item" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.name) +
                                    " [" +
                                    _vm._s(item.loginName) +
                                    "] "
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _vm.activeName === "batchAdd" && _vm.isNonentity
                    ? _c(
                        "el-row",
                        {
                          staticStyle: {
                            "box-shadow": "0px 2px 9px 0px rgba(0, 0, 0, 0.15)",
                            "padding-bottom": "30px",
                            "margin-top": "15px",
                          },
                        },
                        [
                          _c("span", { staticClass: "seleRow" }, [
                            _vm._v("不存在的用户名"),
                          ]),
                          _vm._l(_vm.nonexistence, function (item, index) {
                            return _c(
                              "el-row",
                              { key: index, staticClass: "seleList" },
                              [_c("span", [_vm._v(_vm._s(item))])]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            { attrs: { type: "info" }, on: { click: _vm.close } },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }