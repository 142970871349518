var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务描述：", prop: "description" } },
        [
          _c("Tinymce", {
            ref: "tinymceref",
            attrs: { width: "100%", height: 350 },
            model: {
              value: _vm.formData.description,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "description", $$v)
              },
              expression: "formData.description",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "编码模式：", prop: "type" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.formData.type,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "type", $$v)
                },
                expression: "formData.type",
              },
            },
            [
              _c("el-radio", { attrs: { label: "代码" } }, [_vm._v("代码")]),
              _c("el-radio", { attrs: { label: "演示" } }, [_vm._v("演示")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "预置代码：" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", placeholder: "请输入预置代码", rows: 7 },
            model: {
              value: _vm.formData.code,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "code", $$v)
              },
              expression: "formData.code",
            },
          }),
        ],
        1
      ),
      _vm.formData.type === "代码"
        ? _c(
            "el-form-item",
            { attrs: { label: "完成条件：", prop: "completeCondition" } },
            [_c("span", [_vm._v("提交代码")])]
          )
        : _vm._e(),
      _vm.formData.type === "演示"
        ? _c(
            "el-form-item",
            { attrs: { label: "完成条件：", prop: "completeCondition" } },
            [
              _vm._v(" 至少观看  "),
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.formData.completeCondition,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "completeCondition", $$v)
                  },
                  expression: "formData.completeCondition",
                },
              }),
              _vm._v(" 分钟 "),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "默认开放：", prop: "defOpen" } },
        [
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-text": "开",
              "inactive-text": "关",
            },
            model: {
              value: _vm.formData.defOpen,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "defOpen", $$v)
              },
              expression: "formData.defOpen",
            },
          }),
          _c("span", { staticClass: "inline-block ml-15" }, [
            _vm._v("(设置学员任务默认是否开放)"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }