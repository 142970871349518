<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务内容：" prop="sourceContent">
      <Tinymce tinymceref v-model="formData.sourceContent" width="100%" :height="350" />
    </el-form-item>
    <el-form-item label="上传类型：" prop="uploadType">
      <el-select
        style="width:250px;"
        multiple
        collapse-tags
        @change="forceUpdate"
        v-model="formData.uploadType"
        placeholder="请选择允许的上传类型"
      >
        <el-option v-for="item in uploadTypeArray" :key="item" :label="item" :value="item"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="限制(单位KB)：" required label-width="150px">
      最小值
      <el-input-number style="margin-left: 20px;margin-right: 20px;" v-model="formData.minFileSize" :min="0"></el-input-number>
      最大值
      <el-input-number style="margin-left: 20px;"  v-model="formData.maxFileSize" :max="2147483647"></el-input-number>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      <span>完成上传作品</span>
    </el-form-item>
    <el-form-item label="默认开放：" prop="defOpen">
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
      <span class="inline-block ml-15">(设置学员任务默认是否开放)</span>
    </el-form-item>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { addTask, editTask,getExtList,setInfo } from '@/api/research/curriculum'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { Tinymce },
  methods: {
    updateViewData() {
      this.$refs.tinymceref.setContent(this.formData.description)
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    async getExtList() {
      const response =  await getExtList();
      if(response.state === 'success') {
        this.uploadTypeArray = response.body
      }
    },
    async setInfo(type,taskId) {
      const response =  await setInfo({
        taskId:taskId,
        minSize:this.formData.minFileSize,
        maxSize:this.formData.maxFileSize,
        extList:JSON.stringify(this.formData.uploadType),
      });
      if(response.state === 'success') {
        if(type === 1) {
          $msg("添加成功",0);
        }else {
          $msg("编辑成功",0);
        }
        this.$emit('updateTask')
      }
    },
  },
  mounted() {
    console.log(JSON.stringify(this.formData))
    this.getExtList()
  },
  data () {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请填写任务内容'))
      } else {
        callback()
      }
    }
    return {
      // uploadTypeArray:[],
      uploadTypeArray:[],
      formData: {
        type: '',
        title: '',
        sourceContent: '',
        maxFileSize:0,
        minFileSize:0,
        uploadType:[],
        completeCondition: 1,
        defOpen: false
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
        sourceContent: [
          { required: true, validator: RichTextVal, trigger: 'blur' }
        ],
        uploadType: [
          { required: true, message: "请至少选择一项类型", trigger: 'blur' }
        ]
      },
      addTask () {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            if(this.formData.uploadType.length === 0) {
              $msg("请选择允许上传的类型",2)
              return false
            }else if(this.formData.minFileSize >= this.formData.maxFileSize || this.formData.maxFileSize ===0){
              $msg("文件大小设置非法，请检查",2)
              return false
            }
            addTask({
              type: "上传",
              courseUnitId: this.lessonId,
              title: this.formData.title,
              completeCondition: "完成上传作品",
              sourceContent: this.formData.sourceContent,
              defOpen: this.formData.defOpen
            }).then(res => {
              if (res.state === 'success') {
                this.setInfo(1,res.body)
              }
            })
          }
        })
      },

      editTask () {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            if (valid) {
              if(this.formData.uploadType.length === 0) {
                $msg("请选择允许上传的类型",2)
                return false
              }else if(this.formData.minFileSize >= this.formData.maxFileSize || this.formData.maxFileSize ===0){
                $msg("文件大小设置非法，请检查",2)
                return false
              }
            }

            editTask({
              id: this.editTaskData.id,
              title: this.formData.title, // 标题
              sourceContent: this.formData.sourceContent, // 资源内容
              completeCondition: "完成上传作品", // 任务完成条件
              defOpen: this.formData.defOpen
            }).then(res => {
              if (res.state === 'success') {
                this.setInfo(2,this.editTaskData.id)
              }
            })
          }
        })
      }
    }
  }
}
</script>
