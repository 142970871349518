<template>
  <el-dialog
    :before-close="close"
    :close-on-click-modal="false"
    :visible.sync="show"
    @open="openDialog"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>新增课件</span>
    </div>
    <el-form label-width="150px">
      <el-form-item label="课件地址：">
        <div style="width:67%;display: flex;">
          <div style="display: flex;flex-direction: column;width:100%;">
            <div style="display: flex;">
              <div style="display: flex;flex-direction: column">
                <el-input placeholder="请填写课件标题" v-model="courseTitle"></el-input>
                <div style="display: flex;margin-top: 10px;">
                  <el-upload
                    :auto-upload="false"
                    :before-remove="beforeRemove"
                    :file-list="fileCourseList"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    :on-change="fileCourseChange"
                    :headers  = "headers"
                     ref="uploadCourseFile"
                    accept=".zip"
                    :action="uploadUrl"
                    class="upload-demo"
                    name="file"
                  >
                    <el-button size="small" slot="trigger" type="primary">选取文件</el-button>
                    <el-button
                      @click="submitUpload()"
                      size="small"
                      style="margin-left: 10px;"
                      type="success"
                    >上传到服务器</el-button>
                    <div class="el-upload__tip" slot="tip">只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB!</div>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="补课视频：">
        <div style="width:100%;display: flex;">
          <div style="display: flex;flex-direction: column;width:100%;">
            <div style="display: flex;margin-bottom: 20px;">
              <div style="display: flex;flex-direction: column">
                <el-input placeholder="请填写视频标题" v-model="videoTitle"></el-input>
                <div style="display: flex;margin-top: 10px;">
                  <el-upload
                    :auto-upload="false"
                    :before-remove="beforeRemove"
                    :file-list="fileVideoList"
                    :on-change="fileVideoChange"
                    :headers  = "headers"
                    :on-success="handleAvatarVideoSuccess"
                     ref="uploadVideoFile"
                    :action="uploadVideoUrl"
                    :data="myData"
                    class="upload-demo"
                    name="file"
                  >
                    <el-button size="small" slot="trigger" type="primary">选取视频</el-button>
                    <el-button
                      @click="submitVideoUpload()"
                      size="small"
                      style="margin-left: 10px;"
                      type="success"
                    >上传到服务器</el-button>
                  </el-upload>
                </div>
              </div>
              <el-select v-model="taskId"  placeholder="请选择关联任务">
                <el-option
                  v-for="itemabv in addMissCourseData.taskList"
                  :key="itemabv.id"
                  :label="itemabv.title"
                  :value="itemabv.id" >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { addAttachment } from '@/api/research/curriculum'
import { getToken } from '@/utils/auth'
import { uploadCheck } from '@/api/video/videomanage'
import SparkMD5 from "spark-md5"

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    addMissCourseData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      curriculumID: '', // 课程id
      courseUnitId: 0,
      headers:{Authorization:""},
      fileData: {},
      fileCourseList:[],
      fileVideoList:[],
      courseTitle:"",
      videoPath:"",
      videoTitle:"",
      taskId:"",
      hash:"",
      // uploadVideoUrl: process.env.VUE_APP_API_ADDRESS +'courseUnit/addAttachment',
      uploadVideoUrl:process.env.NODE_ENV === 'development' ? '/video/file/upload' : process.env.VUE_APP_VIDEO_API_ADDRESS + '/file/upload',
      // uploadUrl:  process.env.VUE_APP_API_ADDRESS +'courseUnit/uploadAttachment',
      uploadUrl: process.env.NODE_ENV === 'development' ? '/api/courseUnit/uploadAttachment' : process.env.VUE_APP_API_ADDRESS + 'courseUnit/uploadAttachment',
      uploadForm: new FormData()
    }
  },
  computed: {
    myData(){
      return {
        taskId:'',
        title:'',
        courseUnitId:'',
        type: '',
      }
    }
  },
  methods: {
    fileCourseChange(file,fileList) {
      this.fileCourseList = fileList.splice(-1)
    },
    fileVideoChange(file,fileList) {
      let arr = file.name.split('.')
      if (arr && arr.length > 1) {
        let suffixName = arr[arr.length - 1].toLowerCase()
        if (suffixName !== 'mp4' && suffixName !== 'rmvb'&& suffixName !== 'flv'&& suffixName !== 'avi'&& suffixName !== 'wmv') {
          this.$message.error('文件类型错误，请重新上传！')
          this.fileListArray = []
          return false
        }
      }
      this.fileVideoList = fileList.splice(-1)
      let _this = this
      // element 中组件对 file 进行加工，这里使用未加工的对象，只有未加工的对象才能在 blobSlice.call() 中正常操作
      let fileRaw = file.raw

      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let fileReader = new FileReader()
      let spark = new SparkMD5()
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result)
        _this.hash = spark.end()
      }
      fileReader.onerror = function () {
        console.warn('FileReader error.')
      }
      fileReader.readAsBinaryString(blobSlice.call(fileRaw, 0, 1024*1024))
    },

    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 200
      if (!isLt2M) {
        window.$msg('上传文件不能超过 200MB!', 1)
      }
      return isLt2M
    },

    async submitVideoUpload() {
      this.myData.taskId = this.taskId
      this.myData.title = this.videoTitle
      this.myData.courseUnitId = this.courseUnitId
      this.myData.type = '补课视频'
      if(this.myData.title === ''){
        $msg('请填写视频标题后再上传', 2)
        return false
      }else if(this.fileVideoList.length === 0) {
        $msg("请选择视频后再上传",2)
        return false
      }
      const res = await uploadCheck({
        hash: this.hash
      })
     if(res.code === 0){
        if(res.data === "") {
          this.$refs.uploadVideoFile.submit()
        }else {
          let formdata = new FormData()
          formdata.append("type",'补课视频')
          formdata.append("courseUnitId",this.courseUnitId)
          formdata.append("title",this.videoTitle)
          formdata.append("content",res.data)
          formdata.append("taskId",this.taskId)
          const response = await addAttachment(formdata)
          if(response && response.state === 'success') {
            window.$msg('上传成功')
            this.fileVideoList = []
            this.videoTitle = ""
            this.taskId = ""
            this.myData.taskId = ""
            this.myData.title = ""
            this.myData.courseUnitId = ""
            this.myData.type = ""
          }
        }
      }
    },
    submitUpload (index) {
      if (this.courseTitle === '') {
        window.$msg('请填写课件标题后再上传', 2)
        return false
      }else if(this.fileCourseList.length === 0) {
        $msg("请选择课件后再上传",2)
        return false
      }
      this.$refs.uploadCourseFile.submit()
    },

    async handleAvatarVideoSuccess(res, file, fileList) {
      if (res && res.code === 0) {
        let formdata = new FormData()
        formdata.append("type",'补课视频')
        formdata.append("courseUnitId",this.courseUnitId)
        formdata.append("title",this.videoTitle)
        formdata.append("content",res.data)
        formdata.append("taskId",this.taskId)
        const response = await addAttachment(formdata)
        if(response && response.state === 'success') {
          window.$msg('上传成功')
          this.fileVideoList = fileList.splice(0)
          this.videoTitle = ""
          this.taskId = ""
          this.myData.taskId = ""
          this.myData.title = ""
          this.myData.courseUnitId = ""
          this.myData.type = ""
        }
      } else {
        window.$msg('上传文件失败', 2)
      }
    },
    async handleAvatarSuccess (res, file, fileList) {
      if (res && res.state === 'success') {
        this.uploadForm.append('courseUnitId', this.courseUnitId)
        this.uploadForm.append('title', this.courseTitle)
        this.uploadForm.append('content', res.body)
        this.uploadForm.append('type', '课件')
        await addAttachment(this.uploadForm)
        window.$msg('上传成功')
        this.uploadForm = new FormData()
        this.fileCourseList = []
        this.courseTitle = ""
      } else {
        window.$msg('上传文件失败', 2)
      }
    },

    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },

    openDialog () {
      this.courseUnitId = this.addMissCourseData.id
      this.headers.Authorization = getToken()
    },

    // 关闭弹窗
    close () {
      this.fileVideoList = []
      this.videoTitle = ""
      this.taskId = ""
      this.myData.taskId = ""
      this.myData.title = ""
      this.myData.courseUnitId = ""
      this.myData.type = ""
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
