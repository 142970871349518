var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务链接：", prop: "sourceContent" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写http/https开头的链接" },
            model: {
              value: _vm.formData.sourceContent,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "sourceContent",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.sourceContent",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [
          _vm._v(" 至少观看  "),
          _c("el-input-number", {
            attrs: { min: 1 },
            model: {
              value: _vm.formData.completeCondition,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "completeCondition", $$v)
              },
              expression: "formData.completeCondition",
            },
          }),
          _vm._v(" 分钟 "),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认开放：", prop: "defOpen" } },
        [
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-text": "开",
              "inactive-text": "关",
            },
            model: {
              value: _vm.formData.defOpen,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "defOpen", $$v)
              },
              expression: "formData.defOpen",
            },
          }),
          _c("span", { staticClass: "inline-block ml-15" }, [
            _vm._v("(设置学员任务默认是否开放)"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }