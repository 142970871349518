<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务上传：" prop="sourceContent">
      <div style="display: flex;margin-top: 10px;">
        <el-upload
          :auto-upload="true"
          :before-remove="beforeRemove"
          :file-list="fileList"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          ref="uploadpdf"
          accept=".pdf"
          :action="uploadUrl"
          class="upload-demo"
          name="file"
        >
          <el-button
            @click="submitUpload()"
            size="small"
            type="primary"
          >上传到服务器</el-button>
          <div class="el-upload__tip" slot="tip">只能上传pdf格式文件，且文件名不能包含中文，上传文件不能超过 200MB!</div>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
    <el-form-item label="默认开放：" prop="defOpen">
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
      <span class="inline-block ml-15">(设置学员任务默认是否开放)</span>
    </el-form-item>
  </el-form>
</template>

<script>
import { addTask, editTask } from '@/api/research/curriculum'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      formData: {
        title: '',
        sourceContent: '',
        completeCondition: 1,
        defOpen: false
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
        sourceContent: [{ required: true, message: '请先上传任务', trigger: 'blur' }],
      },
      uploadUrl: process.env.NODE_ENV === 'development' ? '/api/common/file/uploadPdf' : process.env.VUE_APP_API_ADDRESS + 'common/file/uploadPdf',
      fileList: []
    }
  },
  methods: {
    submitUpload () {
      this.$refs.uploadpdf.submit()
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 200
      if (!isLt2M) {
        window.$msg('上传文件不能超过 200MB!', 1)
      }
      return isLt2M
    },
    async handleAvatarSuccess (res, file) {
      if (res && res.state === 'success') {
        this.formData.sourceContent = res.body
        window.$msg('上传成功')
      } else {
        window.$msg('上传文件失败', 2)
      }
    },

    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    addTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          addTask({
            type: 'pdf',
            courseUnitId: this.lessonId,
            title: this.formData.title,
            completeCondition: this.formData.completeCondition,
            sourceContent: this.formData.sourceContent,
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('添加成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    },

    editTask () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editTask({
            id: this.editTaskData.id,
            title: this.formData.title, // 标题
            sourceContent: this.formData.sourceContent, // 资源内容
            completeCondition: this.formData.completeCondition, // 任务完成条件
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    }
  }
}
</script>
