<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    width="70%"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择试卷</span>
    </div>

    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="请输入试卷名称" v-model.trim="inputVal" @keyup.enter.native="search">
          <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option label="试卷名称" value="1"></el-option>
            <el-option label="试卷id" value="2"></el-option>
          </el-select>
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
          <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-table
      style="border-top: 1px solid #EBEEF5;border-left: 1px solid #EBEEF5;margin-top: 25px;"
      :data="tableData"
      border
      fit
      highlight-current-row
      ref="tableData"
      class="paper-table"
      @selection-change="handleSelectionChange"
      @current-change="handleCurrent"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column align="center" label="试卷id" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.id | uuidFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="试卷名称" width="200" prop="title"></el-table-column>

      <el-table-column align="center" label="时间限制/mins" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.limitTime === 0">不限时</span>
          <span v-else>{{ scope.row.limitTime }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" label="是否批阅" width="100" prop="isReview"></el-table-column>

      <el-table-column align="center" label="是否计分" width="100" prop="isShowScore"></el-table-column>

      <el-table-column align="center" label="发布结果" width="100" prop="autoResult"></el-table-column>

      <el-table-column align="center" label="重复交卷" width="100" prop="isRepeatable"></el-table-column>

      <el-table-column align="center" label="状态" width="100" prop="status"></el-table-column>

      <el-table-column align="center" label="创建时间">
        <template slot-scope="scope">
          <span>{{scope.row.createTime | dateFormat}}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-row>
      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>

    <!-- footer -->
    <el-row slot="footer" style="text-align: left;">
      <el-button type="primary" @click="savePaper()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getExamList } from '@/api/research/exam'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      inputVal: '', // 检索文本
      select: '1', // 检索条件
      tableData: [],
      seleData: null, // 选中数据
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {},
  methods: {
    openDialog () {
      this.seleData = null
      this.getQuestionInfo()
    },

    // 获取试题详细信息
    async getQuestionInfo () {
      this.tableData = []
      const res = await getExamList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        title: this.select === '1' ? this.inputVal : '',
        idKey: this.select === '2' ? this.inputVal : '',
        statusList: ['已发布'],
        isUsed: false // 未被任务关联
      })
      res.body.list.forEach((item, index) => {
        if (!item.taskList.length) {
          this.tableData.push(item)
        } else {
          console.log('试卷已被任务关联')
        }
      })
      this.tableData = res.body.list
      this.pagination.total = res.body.total
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 选择题目
    seleTaskPaper () {},

    handleSelectionChange (val) {
      this.seleData = val
      if (val.length > 1) {
        this.$refs.tableData.clearSelection()
        this.$refs.tableData.toggleRowSelection(val.pop())
      }
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getQuestionInfo()
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getQuestionInfo()
    },

    // 任意位置勾选
    handleCurrent (val) {
      this.$refs.tableData.toggleRowSelection(val)
    },

    // 搜索
    search () {
      this.pagination.currentPage = 1
      this.getQuestionInfo()
    },

    // 重置
    reset () {
      this.inputVal = ''
      this.select = '1'
      this.seleType = ''
    },

    // 添加/保存试卷
    savePaper () {
      if (!this.seleData) {
        window.$msg('无勾选数据', 2)
      } else {
        this.$emit('savePaper', this.seleData)
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";

/deep/ thead {
  .el-table-column--selection {
    .cell {
      display: none;
    }
  }
}
</style>
