var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-table",
        {
          staticClass: "tb-list mt-15",
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: { data: _vm.dataList, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名次", align: "center", prop: "rank" },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "总积分", align: "center", prop: "score" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }