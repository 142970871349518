<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务内容：" prop="description">
      <Tinymce tinymceref v-model="formData.description" width="100%" :height="350" />
    </el-form-item>
    <el-form-item label="关联模板：" prop="sourceId">
      <el-select
        style="width:250px;"
        filterable
        v-model="formData.sourceId"
        placeholder="请关联和任务相关的模板"
      >
        <el-option v-for="item in moduleArray" :key="item.id" :label="item.title+'  ['+item.creatorName+']'" :value="item.id">

        </el-option>
      </el-select>
      <el-button style="margin-left: 30px;" type="primary" size="medium" @click="add" class="tb-button">新增模板</el-button>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      <span>完成上传作品</span>
    </el-form-item>
    <el-form-item label="默认开放：" prop="defOpen">
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
      <span class="inline-block ml-15">(设置学员任务默认是否开放)</span>
    </el-form-item>
    <!-- add -->
    <add-scratch-modle
      :show="addMoudleDialog"
      @close="addMoudleDialog=false"
      @scratchmoduleList="scratchmoduleList"
    ></add-scratch-modle>
  </el-form>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { addTask, editTask } from '@/api/research/curriculum'
import { scratchmoduleList } from '@/api/research/scratch'
import  AddScratchModle  from  '@/components/research/quesbank-manage/addScratchModle'

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { Tinymce ,AddScratchModle},
  mounted() {
    this.scratchmoduleList("")
  },
  methods: {
    updateViewData() {
      this.$refs.tinymceref.setContent(this.formData.description)
    },
    async scratchmoduleList(data) {
      const response = await scratchmoduleList({
        pageNum: 1,
        pageSize: 10000,
      })
      this.moduleArray = response.body.list
      if(data !== "") {
        this.formData.sourceId = data
      }
    },
    add() {
      this.addMoudleDialog = true
    },
  },
  data () {
    let RichTextVal = (rule, value, callback) => {
      if (value === '<p><br></p>' || !value) {
        callback(new Error('请填写任务内容'))
      } else {
        callback()
      }
    }
    return {
      moduleArray:[],
      addMoudleDialog:false,
      formData: {
        type: '',
        description:'',
        title: '',
        sourceContent: '',
        sourceId:"",
        completeCondition: 1,
        defOpen: false,
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
        description: [{ required: true, validator: RichTextVal, trigger: 'blur' }],
        sourceId: [
          { required: true, message: "请关联模板", trigger: 'blur' }
        ]
      },

      addTask () {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            if(this.formData.sourceId === "") {
              $msg("请选择关联此任务的模板",2)
              return false
            }
            addTask({
              type: "scratch",
              courseUnitId: this.lessonId,
              title: this.formData.title,
              completeCondition: "完成上传作品",
              description:this.formData.description,
              sourceContent: this.formData.sourceId,
              defOpen: this.formData.defOpen
            }).then(res => {
              if(res.state === 'success') {
                window.$msg('添加成功')
                this.$emit('updateTask')
              }
            })
          }
        })
      },

      editTask () {
        this.$refs['formData'].validate(valid => {
          if (valid) {
            if(this.formData.sourceId.length === 0) {
              $msg("请选择关联此任务的模板",2)
              return false
            }
          }

          editTask({
            id: this.editTaskData.id,
            title: this.formData.title, // 标题
            sourceId: this.formData.sourceId,
            description:this.formData.description,// 任务描述
            completeCondition: "完成上传作品", // 任务完成条件
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        })
      }
    }
  }
}
</script>
