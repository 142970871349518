var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("课程管理")]),
      _c(
        "el-tabs",
        {
          staticClass: "tabs-con",
          staticStyle: { "min-height": "200px", "margin-top": "35px" },
          attrs: { "tab-position": "left" },
          on: { "tab-click": _vm.changeTab },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "baseInfo" } },
            [
              _c("base-info", {
                attrs: {
                  curriculumID: _vm.curriculumID,
                  cascaderList: _vm.cascaderList,
                  CourseInfo: _vm.CourseInfo,
                },
                on: { updatBaseInfo: _vm.updatBaseInfo },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "课程计划", name: "LessonPlan" } },
            [
              _c("curriculum-plan", {
                attrs: {
                  curriculumID: _vm.curriculumID,
                  CourseInfo: _vm.CourseInfo,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "课程学员", name: "LessonStudent" } },
            [_c("student-list")],
            1
          ),
          _vm.CourseInfo.type === "刷题"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "排行榜", name: "rankList" } },
                [_c("rank-list", { attrs: { CourseInfo: _vm.CourseInfo } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-backtop", {
        attrs: {
          bottom: 40,
          right: 60,
          "visibility-height": 50,
          target: ".table",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }