<template>
  <el-form ref="formData" :model="formData" label-width="120px" :rules="rules">
    <el-form-item label="任务标题：" prop="title">
      <el-input v-model.trim="formData.title" placeholder="请填写任务标题" maxlength="32"></el-input>
    </el-form-item>
    <el-form-item label="任务上传：" prop="sourceContent">
      <div style="display: flex;">
        <el-upload
          :auto-upload="false"
          :before-remove="beforeRemove"
          :file-list="fileListArray"
          :on-change="onFileChange"
          :headers  = "headers"
          :on-success="handleAvatarVideoSuccess"
          ref="uploadfile"
          :action="uploadVideoUrl"
          class="upload-demo"
          name="file"
        >
          <el-button size="small" slot="trigger" type="primary">选取视频</el-button>
          <el-button
            @click="uploadCheck()"
            size="small"
            style="margin-left: 10px;"
            type="success"
          >上传到服务器</el-button>
        </el-upload>
      </div>
    </el-form-item>
    <el-form-item label="完成条件：" prop="completeCondition">
      至少观看&nbsp;
      <el-input-number v-model="formData.completeCondition" :min="1"></el-input-number>&nbsp;分钟
    </el-form-item>
    <el-form-item label="默认开放：" prop="defOpen">
      <el-switch
        v-model="formData.defOpen"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-text="开"
        inactive-text="关">
      </el-switch>
      <span class="inline-block ml-15">(设置学员任务默认是否开放)</span>
    </el-form-item>
  </el-form>
</template>

<script>
import { addTask, editTask } from '@/api/research/curriculum'
import { uploadCheck } from '@/api/video/videomanage'
import SparkMD5 from "spark-md5"

export default {
  props: {
    lessonId: {
      type: String,
      default: ''
    },
    headers:{
      default: () => {
        return {
          Authorization:""
        }
      },
      type: Object
    },
    editTaskData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      fileListArray: [],
      uploadVideoUrl: process.env.NODE_ENV === 'development' ? '/file/upload' : process.env.VUE_APP_VIDEO_API_ADDRESS + '/file/upload',
      hash:"",
      formData: {
        title: '',
        sourceContent: '',
        completeCondition: 1,
        defOpen: false
      },
      rules: {
        title: [{ required: true, message: '请填写任务标题', trigger: 'blur' }],
      },
      uploadUrl: process.env.NODE_ENV === 'development' ? '/api/common/file/uploadPdf' : process.env.VUE_APP_API_ADDRESS + 'common/file/uploadPdf',
      fileList: []
    }
  },

  methods: {

    async uploadCheck () {
      if(this.fileListArray.length === 0) {
        $msg("请先选择视频在上传",2)
        return false
      }
      let _this = this
      const res = await uploadCheck({
        hash: _this.hash
      })
      if(res.code === 0){
        if(res.data === "") {
          this.$refs.uploadfile.submit()
        }else {
          this.formData.sourceContent = res.data
          window.$msg('上传成功')
        }
      }
    },
    onFileChange (file, fileList) {
      let arr = file.name.split('.')
      if (arr && arr.length > 1) {
        let suffixName = arr[arr.length - 1].toLowerCase()
        if (suffixName !== 'mp4' && suffixName !== 'rmvb'&& suffixName !== 'flv'&& suffixName !== 'avi'&& suffixName !== 'wmv') {
          this.$message.error('文件类型错误，请重新上传！')
          this.fileListArray = []
          return false
        }
      }
      this.fileListArray = fileList.slice(-1);
      let _this = this
      // element 中组件对 file 进行加工，这里使用未加工的对象，只有未加工的对象才能在 blobSlice.call() 中正常操作
      let fileRaw = file.raw

      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let fileReader = new FileReader()
      let spark = new SparkMD5()
      fileReader.onload = function (e) {
        spark.appendBinary(e.target.result)
        _this.hash = spark.end()
      }
      fileReader.onerror = function () {
        console.warn('FileReader error.')
      }
      fileReader.readAsBinaryString(blobSlice.call(fileRaw, 0, 1024*1024))
    },

    handleAvatarVideoSuccess(res,file) {
      if (res && res.code === 0) {
        this.formData.sourceContent = res.data
        window.$msg('上传成功')
      } else {
        window.$msg('上传文件失败', 2)
      }
    },

    beforeRemove (file, fileList) {
      return  this.$confirm(`确定移除 ${file.name}？`).then(()=>{
        this.fileListArray = []
        return true
      })
    },

    addTask () {
      let _this = this
      if(this.formData.sourceContent === "") {
        $msg("请先上传任务到服务器",1)
        return false
      }
      this.$refs['formData'].validate(valid => {
        if (valid) {
          addTask({
            type: '视频',
            courseUnitId: this.lessonId,
            title: this.formData.title,
            completeCondition: this.formData.completeCondition,
            sourceContent: this.formData.sourceContent,
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              _this.fileListArray = []
              window.$msg('添加成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    },

    editTask () {
      let _this = this
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editTask({
            id: this.editTaskData.id,
            title: this.formData.title, // 标题
            sourceContent: this.formData.sourceContent === null?this.editTaskData.sourceId:this.formData.sourceContent, // 资源内容
            completeCondition: this.formData.completeCondition, // 任务完成条件
            defOpen: this.formData.defOpen
          }).then(res => {
            if (res.state === 'success') {
              _this.fileListArray = []
              window.$msg('编辑成功')
              this.$emit('updateTask')
            }
          })
        }
      })
    }
  }
}
</script>
