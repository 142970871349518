<template>
  <el-row class="table">
    <el-row class="row-bg">
      <el-input
        class="tb-sm-input"
        v-model.trim="queryData.inputVal"
        placeholder="请输入学号"
        @keyup.enter.native="search">
      </el-input>
      <el-input
        class="tb-sm-input"
        v-model.trim="queryData.inputName"
        placeholder="请输入姓名"
        @keyup.enter.native="search">
      </el-input>
      <el-select v-model="queryData.seleState" placeholder="选择状态" class="tb-smselect">
        <el-option v-for="(item, index) in queState" :key="index" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" size="small" @click="search">搜 索</el-button>
      <el-button type="primary" size="small" @click="add">新 增</el-button>
      <el-button type="info" size="small" @click="reset">重 置</el-button>
    </el-row>
     <el-table
      :data="dataList"
      class="tb-list ml-10"
      style="width: 100%;margin-bottom: 20px;"
      border>
        <el-table-column label="学号" align="center" prop="studentNo"></el-table-column>
        <el-table-column label="姓名" align="center" prop="studentName"></el-table-column>
        <el-table-column label="开放时间" align="center" prop="createTime"></el-table-column>
        <el-table-column label="当前状态" align="center" prop="status"></el-table-column>
        <el-table-column label="操作" align="center" width="230">
          <template slot-scope="scope">
            <el-button type="text" v-has="'class:updatestudentcourse'" @click="closeCourse(scope.row)" v-if="scope.row.status === '开放'">关闭课程</el-button>
            <el-button type="text" v-has="'class:updatestudentcourse'" @click="closeCourse(scope.row)" v-else>开放课程</el-button>
            <el-button type="text" v-has="'class:studentcourse'" @click="ManStudentTask(scope.row)">管理学员任务</el-button>
          </template>
        </el-table-column>
     </el-table>

     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- 学员课程dialog -->
    <student-course :resetUse="studentCourseObj" :show="dialogStuCourse" @close="dialogStuCourse=false"></student-course>
    <!-- 课程管理新增学员 -->
    <course-student :show="studentDialog" @close="studentDialog=false" @updateView="getUserListByCourse"></course-student>
  </el-row>
</template>

<script>
import { getUserListByCourse, setStudentStatus } from '@/api/research/curriculum'
import studentCourse from '@/components/senate/detail-pane/student-course'
import courseStudent from '@/components/research/curriculum-manage/components/course-student'

export default {
  components: { studentCourse, courseStudent },
  data () {
    return {
      dataList: [],
      queryData: {
        inputVal: '',
        inputName: '',
        seleState: ''
      },
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      queState: [
        {
          value: '开放',
          label: '开放'
        },
        {
          value: '关闭',
          label: '关闭'
        }
      ],
      studentCourseObj: null,
      dialogStuCourse: false,
      studentDialog: false
    }
  },
  created () {
    this.getUserListByCourse()
  },
  methods: {
    async getUserListByCourse () {
      const res = await getUserListByCourse({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        courseId: this.$route.query.curriculumID,
        studentName: this.queryData.inputName ? this.queryData.inputName : null,
        studentNo: this.queryData.inputVal ? this.queryData.inputVal : null,
        status: this.queryData.seleState ? this.queryData.seleState : null
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 关闭课程
    async closeCourse (row) {
      await setStudentStatus({
        courseId: this.$route.query.curriculumID,
        status: row.status === '开放' ? '关闭' : '开放',
        userIdSet: [row.userId]
      })
      window.$msg('操作成功')
      this.getUserListByCourse()
    },

    // 管理学员任务
    ManStudentTask (row) {
      this.studentCourseObj = {
        loginName: row.studentNo,
        userName: row.studentName,
        user_id: row.userId,
        class_id: row.studentClassId
      }
      this.dialogStuCourse = true
    },

    search () {
      this.pagination.currentPage = 1
      this.getUserListByCourse()
    },

    reset () {
      this.queryData.inputVal = null
      this.queryData.inputName = null
      this.queryData.seleState = null
    },

    add () {
      this.studentDialog = true
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getUserListByCourse()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getUserListByCourse()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
</style>
