var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.close,
        "close-on-click-modal": false,
        visible: _vm.show,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("新增课件")])]
      ),
      _c(
        "el-form",
        { attrs: { "label-width": "150px" } },
        [
          _c("el-form-item", { attrs: { label: "课件地址：" } }, [
            _c("div", { staticStyle: { width: "67%", display: "flex" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    width: "100%",
                  },
                },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请填写课件标题" },
                          model: {
                            value: _vm.courseTitle,
                            callback: function ($$v) {
                              _vm.courseTitle = $$v
                            },
                            expression: "courseTitle",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "uploadCourseFile",
                                staticClass: "upload-demo",
                                attrs: {
                                  "auto-upload": false,
                                  "before-remove": _vm.beforeRemove,
                                  "file-list": _vm.fileCourseList,
                                  "before-upload": _vm.beforeAvatarUpload,
                                  "on-success": _vm.handleAvatarSuccess,
                                  "on-change": _vm.fileCourseChange,
                                  headers: _vm.headers,
                                  accept: ".zip",
                                  action: _vm.uploadUrl,
                                  name: "file",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "trigger",
                                      size: "small",
                                      type: "primary",
                                    },
                                    slot: "trigger",
                                  },
                                  [_vm._v("选取文件")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { size: "small", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitUpload()
                                      },
                                    },
                                  },
                                  [_vm._v("上传到服务器")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      "只能上传zip格式，且文件名不能包含中文，上传文件不能超过 200MB!"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "补课视频：" } }, [
            _c("div", { staticStyle: { width: "100%", display: "flex" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "20px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请填写视频标题" },
                            model: {
                              value: _vm.videoTitle,
                              callback: function ($$v) {
                                _vm.videoTitle = $$v
                              },
                              expression: "videoTitle",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "uploadVideoFile",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    "auto-upload": false,
                                    "before-remove": _vm.beforeRemove,
                                    "file-list": _vm.fileVideoList,
                                    "on-change": _vm.fileVideoChange,
                                    headers: _vm.headers,
                                    "on-success": _vm.handleAvatarVideoSuccess,
                                    action: _vm.uploadVideoUrl,
                                    data: _vm.myData,
                                    name: "file",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "trigger",
                                        size: "small",
                                        type: "primary",
                                      },
                                      slot: "trigger",
                                    },
                                    [_vm._v("选取视频")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { size: "small", type: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.submitVideoUpload()
                                        },
                                      },
                                    },
                                    [_vm._v("上传到服务器")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择关联任务" },
                          model: {
                            value: _vm.taskId,
                            callback: function ($$v) {
                              _vm.taskId = $$v
                            },
                            expression: "taskId",
                          },
                        },
                        _vm._l(
                          _vm.addMissCourseData.taskList,
                          function (itemabv) {
                            return _c("el-option", {
                              key: itemabv.id,
                              attrs: {
                                label: itemabv.title,
                                value: itemabv.id,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }