var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "formData",
      attrs: { model: _vm.formData, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "任务标题：", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请填写任务标题", maxlength: "32" },
            model: {
              value: _vm.formData.title,
              callback: function ($$v) {
                _vm.$set(
                  _vm.formData,
                  "title",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "formData.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任务内容：", prop: "description" } },
        [
          _c("Tinymce", {
            attrs: { tinymceref: "", width: "100%", height: 350 },
            model: {
              value: _vm.formData.description,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "description", $$v)
              },
              expression: "formData.description",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "关联模板：", prop: "sourceId" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "250px" },
              attrs: { filterable: "", placeholder: "请关联和任务相关的模板" },
              model: {
                value: _vm.formData.sourceId,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "sourceId", $$v)
                },
                expression: "formData.sourceId",
              },
            },
            _vm._l(_vm.moduleArray, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: {
                  label: item.title + "  [" + item.creatorName + "]",
                  value: item.id,
                },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              staticStyle: { "margin-left": "30px" },
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.add },
            },
            [_vm._v("新增模板")]
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "完成条件：", prop: "completeCondition" } },
        [_c("span", [_vm._v("完成上传作品")])]
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认开放：", prop: "defOpen" } },
        [
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-text": "开",
              "inactive-text": "关",
            },
            model: {
              value: _vm.formData.defOpen,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "defOpen", $$v)
              },
              expression: "formData.defOpen",
            },
          }),
          _c("span", { staticClass: "inline-block ml-15" }, [
            _vm._v("(设置学员任务默认是否开放)"),
          ]),
        ],
        1
      ),
      _c("add-scratch-modle", {
        attrs: { show: _vm.addMoudleDialog },
        on: {
          close: function ($event) {
            _vm.addMoudleDialog = false
          },
          scratchmoduleList: _vm.scratchmoduleList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }