<!--
 * @Author: your name
 * @Date: 2021-01-13 09:34:39
 * @LastEditTime: 2021-01-13 14:45:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \edu_ui\src\components\research\curriculum-manage\rankList.vue
-->
<template>
  <el-row class="table">
     <el-table
      :data="dataList"
      class="tb-list mt-15"
      style="width: 100%;margin-bottom: 20px;"
      border>
        <el-table-column label="名次" align="center" prop="rank"></el-table-column>
        <el-table-column label="姓名" align="center" prop="name"></el-table-column>
        <el-table-column label="总积分" align="center" prop="score"></el-table-column>
     </el-table>

     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { ranking } from '@/api/research/curriculum'

export default {
   props: {
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
    }
  },
  created () {
    this.getRanking()
  },
  methods: {
    async getRanking () {
      const res = await ranking({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        courseId: this.$route.query.curriculumID,
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getRanking()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getRanking()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
</style>
