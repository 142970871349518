var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c("h3", { staticClass: "tab-title" }, [_vm._v("基本信息")]),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程昵称：" } },
            [
              _c("el-input", {
                staticClass: "tab-input",
                attrs: {
                  placeholder: "请输入课程昵称（显示给购买课程的学员的名称）",
                },
                model: {
                  value: _vm.formData.nick,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "nick", $$v)
                  },
                  expression: "formData.nick",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程名称：", prop: "title" } },
            [
              _c("el-input", {
                staticClass: "tab-input",
                attrs: {
                  placeholder: "请输入课程名称（输入课程名称不可重复）",
                },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类别：", prop: "catalogId" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.categoryList,
                  props: { checkStrictly: true, value: "id", label: "title" },
                },
                model: {
                  value: _vm.formData.catalogId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "catalogId", $$v)
                  },
                  expression: "formData.catalogId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "知识点：" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "collapse-tags": "",
                    multiple: "",
                    placeholder: "请选择知识点",
                  },
                  model: {
                    value: _vm.formData.knowledge,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "knowledge", $$v)
                    },
                    expression: "formData.knowledge",
                  },
                },
                _vm._l(_vm.knowledgeList, function (item) {
                  return _c("el-option", {
                    key: item.knowledgeId,
                    attrs: { label: item.content, value: item.knowledgeId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择课程类型" },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "curriculum:ManageEdit",
                      expression: "'curriculum:ManageEdit'",
                    },
                  ],
                  staticStyle: { "margin-left": "65px" },
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.editCurriculum },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }