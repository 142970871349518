<template>
  <el-row>
    <h3 class="tab-title">基本信息</h3>
    <!-- formData：{{formData}} -->
    <!-- CourseInfo：{{CourseInfo}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="课程昵称：">
        <el-input v-model="formData.nick" placeholder="请输入课程昵称（显示给购买课程的学员的名称）" class="tab-input"></el-input>
      </el-form-item>
      <el-form-item label="课程名称：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入课程名称（输入课程名称不可重复）" class="tab-input"></el-input>
      </el-form-item>
      <el-form-item label="课程类别：" prop="catalogId">
        <!-- 级联选择器 -->
        <el-cascader
          v-model="formData.catalogId"
          :options="categoryList"
          :props="{ checkStrictly: true, value: 'id', label: 'title' }">
        </el-cascader>
      </el-form-item>
      <el-form-item label="知识点：">
        <el-select v-model="formData.knowledge" collapse-tags multiple placeholder="请选择知识点">
          <el-option
            v-for="item in knowledgeList"
            :key="item.knowledgeId"
            :label="item.content"
            :value="item.knowledgeId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程类型：">
        <el-select v-model="formData.type" placeholder="请选择课程类型">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="medium" type="primary" style="margin-left: 65px;" @click="editCurriculum" v-has="'curriculum:ManageEdit'">保存</el-button>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute curriculumID 课程id
 * @attribute cascaderList 级联选择器所需数据
 * @attribute CourseInfo  课程基本信息
 */
import { editCourse } from '@/api/research/curriculum'
import { listKnowledgePoint } from '@/api/research/knowledge'

export default {
  props: {
    curriculumID: {
      default: '',
      type: String
    },
    cascaderList: {
      default: () => {
        return []
      },
      type: Array
    },
    CourseInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    CourseInfo (newVal, oldVal) {
      this.CourseInfo.knowledge = []
      this.formData = { ...this.CourseInfo }
      this.$nextTick(()=> {
        this.listKnowledgePoint()
      })
    }
  },
  data () {
    return {
      knowledgeList:[],
      formData: {},
      rules: {
        title: [
          { required: true, message: '课程名称不能为空', trigger: 'blur' },
          { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
        ],
        catalogId: [{ required: true, message: '类别ID不能为空', trigger: 'change' }]
      },
      categoryList: [],
      typeList: [{
        value: '常规',
        label: '常规'
      }, {
        value: '试听',
        label: '试听'
      }, {
        value: '刷题',
        label: '刷题'
      }]
    }
  },
  mounted () {
    // 延时执行渲染数据
    setTimeout(() => {
      this.formData = { ...this.CourseInfo }
      this.categoryList = [...this.cascaderList]
    }, 500)
  },
  methods: {
    async listKnowledgePoint() {
      this.knowledgeList = []
      const res  = await listKnowledgePoint({
        pageNum:1,
        pageSize:100000,
        needCount:true
      })
      if(res && res.state === 'success') {
        this.knowledgeList = res.body.list
      }
      this.listKnowledgeCoursePoint()
    },
    async listKnowledgeCoursePoint() {
      const res  = await listKnowledgePoint({
        extId:this.CourseInfo.id,
        pageNum:1,
        pageSize:100000,
        needCount:true
      })
      if(res && res.state === 'success') {
        this.formData.knowledge = []
         res.body.list.forEach((item)=> {
           this.formData.knowledge.push(item.knowledgeId)
         })
      }
    },
    editCurriculum () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          editCourse({
            id: this.formData.id,
            catalogId: typeof (this.formData.catalogId) === 'string' ? this.formData.catalogId : this.formData.catalogId[this.formData.catalogId.length - 1],
            title: this.CourseInfo.title === this.formData.title ? null : this.formData.title,
            nick: this.formData.nick,
            type: this.formData.type,
            knowledgeIds:this.formData.knowledge
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('编辑成功')
              this.$emit('updatBaseInfo')
              this.listKnowledgePoint()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-title {
  padding-left: 35px;
  margin: 20px 0;
  font-size: 16px;
  color: #464646;
  font-weight: 600
}
.tab-input {
  width: 350px;
}
/deep/ .el-form-item {
  margin-bottom: 20px;
}
</style>
