<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    width="70%"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课程管理新增学员</span>
    </div>

    <el-row>
      <el-row :gutter="60">
        <el-col :span="14">
          <el-row style="margin-bottom:20px">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部学员" name="allList">
              <el-row>
                <el-input
                  placeholder="请输入内容"
                  v-model="inputVal"
                  clearable
                  class="input-with-select"
                  @keyup.enter.native="search"
                >
                  <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 100px;">
                    <el-option label="用户名" value="1"></el-option>
                    <el-option label="姓名" value="2"></el-option>
                  </el-select>
                  <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
              </el-row>
              <el-table
                :data="dataList"
                ref="tableData"
                border
                fit
                :row-key="getRowKeys"
                highlight-current-row
                style="margin-top: 25px"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  align="center"
                  :reserve-selection="true"
                  width="55">
                </el-table-column>
                <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
                <el-table-column label="姓名" align="center" prop="name"></el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.currentPage"
                :page-sizes="pagination.pageSizes"
                :page-size="pagination.pageSize"
                :layout="pagination.layout"
                :total="this.pagination.total"
              ></el-pagination>
            </el-tab-pane>
            <el-tab-pane label="批量添加" name="batchAdd">
              <el-input
                type="textarea"
                placeholder="S0001
S0002
S0003
……
……
请按每行一个学号的格式填写要添加的学员，点击导入按钮"
                rows="8"
                v-model="batchStr"
                show-word-limit
              >
              </el-input>
              <el-button size="mini" type="primary" @click="importFun" style="margin-top: 10px;">导入</el-button>
            </el-tab-pane>
          </el-tabs>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;">
            <el-row class="seleRow">
              <span>已选择：</span>
              <span class="seleNum" v-text="multipleSelectionTotal.length"></span>
            </el-row>
            <ul v-for="(item, index) of multipleSelectionTotal" :key="index" class="seleList">
              <li class="sele-item">
                {{item.name}} [{{item.loginName}}]
              </li>
            </ul>
          </el-row>

          <el-row style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;margin-top: 15px;" v-if="activeName === 'batchAdd' && isNonentity">
            <span class="seleRow">不存在的用户名</span>
            <el-row v-for="(item, index) of nonexistence" :key="index" class="seleList">
              <span>{{item}}</span>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
    </el-row>

    <el-row slot="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="close" type="info">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getUserList } from '@/api/system/staff'
import { setListByLoginNames } from '@/api/senate/class'
import { mapStu } from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  // 监控表单变化，清除验证
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      select: '',
      activeName: 'allList',
      inputVal: '',
      isNonentity: false,
      batchStr: '',
      nonexistence: [],
      multipleSelectionTotal: [], // 已选择学员
    }
  },
  methods: {
    getRowKeys (row) {
      return row.id
    },

    handleClick() {},

    importFun() {
      let loninNameSet = this.batchStr.split('\n')
      setListByLoginNames({
        roleCodeSet: [1],
        loninNameSet
      }).then(res => {
        if (res.state === 'success') {
          this.multipleSelectionTotal = res.body.existence.concat(this.multipleSelectionTotal)
          if (res.body.nonexistence.length === 0) {
            this.isNonentity = false
          } else {
            this.nonexistence = res.body.nonexistence
            this.isNonentity = true
          }
        }
      })
    },

    openDialog () {
      this.getUserList()
      // this.$refs.tableData.clearSelection()
    },

    async getUserList () {
      const res = await getUserList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        roleCodeSet: [1],
        loginName: this.select === '1' ? this.inputVal : '',
        name: this.select === '2' ? this.inputVal : ''
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    confirm () {
      if (this.multipleSelectionTotal.length === 0) {
        window.$msg('无勾选学员', 2)
      } else {
        this.multipleSelectionTotal.forEach((ele, idx) => {
          // console.log('idx', idx)
          // console.log('multipleSelectionTotal', this.multipleSelectionTotal.length)
          mapStu({
            courseId: this.$route.query.curriculumID,
            userId: ele.userId
          }).then(res => {
            if (res.state === 'success' && idx === this.multipleSelectionTotal.length-1) {
              window.$msg('新增成功')
              this.close()
              this.$emit('updateView')
            }
          })
        })
      }
    },

    search () {
      this.pagination.currentPage = 1
      this.getUserList()
    },

    handleSelectionChange (val) {
      this.multipleSelectionTotal = val
      // if (val.length > 1) {
      //   this.$refs.tableData.clearSelection()
      //   this.$refs.tableData.toggleRowSelection(val.pop())
      // }
    },

    close () {
      this.$emit('close')
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getUserList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getUserList()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/dialog.scss';
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-right: 50px;
  cursor: pointer;
}

</style>
